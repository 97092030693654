import notify from 'devextreme/ui/notify'

function error(message) {
	notify(message, 'error', 5000)
}

function success(message) {
	notify(message, 'success', 5000)
}

function warning(message) {
	notify(message, 'warning', 5000)
}

export const alert = {
	TEXT_SAVE_SUCCESS: 'Changes were successfully saved',
	TEXT_SAVE_FAILED: 'Failed to save changes',
	TEXT_ADD_SUCCESS: 'Added successfully',
	TEXT_ADD_FAILED: 'Failed to add changes',
	error,
	success,
	warning
}
