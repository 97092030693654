<script>

export default {
	name: 'AgentGrid',
	components: { },
	props: {
		agents: {
			type: Array,
			default: function() {
				return []
			}
		},
		method: { type: Function },
		activeClick: { type: Function },
		openModal: { type: Function }
	},
	data() {
		return {
			value: [],
			options: []
		}
	},
	methods: {
		getFirstLetter(name) {
			return name?.charAt(0)?.toUpperCase()
		},
		clickRow(data) {
			this.method(data.data)
		},
		getSearchClass() {
			return this.loading ? 'bx-loader bx-spin' : 'bxs-user-plus'
		},
		getUserIconClass(id) {
			return id % 2 === 0 ? 'bg-soft-success text-success' : 'bg-soft-info text-info'
		}
	}
}
</script>

<template>
	<div>
		<div class="row">
			<div v-for="agent in agents" :key="agent.id" class="col-xl-4 col-sm-6">
				<div class="card">
					<div class="row">
						<div class="col-xl-7 col-lg-6 border-right">
							<div class="text-center pb-2 pt-4">
								<div v-if="agent.pictureUrl" class="avatar-sm mr-3 mx-lg-auto mb-4 float-left float-lg-none">
									<img :src="agent.pictureUrl" alt height="50" width="50" />
								</div>
								<div v-else class="avatar-sm mr-3 mx-lg-auto mb-4 float-left float-lg-none">
									<span :class="`avatar-title rounded-circle ${getUserIconClass(agent.id)} font-size-16`">
										{{ getFirstLetter(agent.firstName) }}
									</span>
								</div>
								<div class="pl-2">
									<h5 class="text-truncate">{{ agent.fullName }}</h5>
									<h6 class="text-truncate">{{ agent.email }}</h6>
								</div>
							</div>
						</div>

						<div class="col-xl-5 col-lg-6">
							<div class="pt-4 text-center text-xl-left">
								<div class="row">
									<div class="col-sm-12 text-center">
										<div>
											<b-form-checkbox v-model="agent.active" switch class="mb-1" @change="activeClick(agent)">
												<label>Active</label>
											</b-form-checkbox>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-12 text-center">
										 <b-button class="m-2 font-size-16" variant="none" @click="openModal(agent)">
											<i class="mdi mdi-pencil"></i>
										</b-button>
									</div>
								</div>
								<div class="row mt-lg-5 py-1 border-top">
									<div class="col-sm-6">
										<span v-b-tooltip.hover title="Date"><i class="bx bx-calendar mr-1"></i>14 Oct, 19</span>
									</div>
									<div class="col-sm-6">
										<span v-b-tooltip.hover title="Tickets"><i class="bx bxs-coupon mr-1"></i>202</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
