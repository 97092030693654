<script>
import Toolbar from './toolbar'

import EmailCard from './EmailCard'
import EmailEditor from './EmailEditor'

export default {
	name: 'EmailPanel',
	page: {
		title: 'Read Email'
	},
	props: {
		messages: { type: Array, required: true },
        showHeader: { type: Boolean, required: false, default: false },
	},
	components: {
		Toolbar,
		EmailCard,
		EmailEditor
	},
	data() {
		return {
			items: [
				{
					text: 'Email',
					href: '/'
				},
				{
					text: 'Read Email',
					active: true
				}
			],
			replayData: null
		}
	},
	created() {},
	methods: {
		replayClick(data) {
			this.replayData = data

			this.$nextTick(() => {
				const el = this.$refs.emailEditor;

				if (el) {
					el.$el.scrollIntoView({behavior: 'smooth'});
				}
			})
		}
	}
}
</script>

<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="btn-toolbar p-3" v-if="showHeader" role="toolbar">
					<Toolbar />
				</div>
				<div class="accordion" role="tablist">
					<div :key="index" v-for="(data, index) in messages" role="tablist">
						<EmailCard :email="data" :id="index" :replay-click="replayClick" />
					</div>
				</div>
				<div v-if="replayData">
					<EmailEditor :email="replayData" ref="emailEditor" />
				</div>
			</div>
		</div>
	</div>
</template>
