<script>
import {
	DxDataGrid,
	DxColumn,
	DxGrouping,
	DxGroupPanel,
	DxPager,
	DxPaging,
	DxSearchPanel,
	DxExport,
	DxEditing,
	DxRequiredRule,
	DxEmailRule,
	DxFormItem,
	DxLookup,
	DxButton
} from 'devextreme-vue/data-grid'
import { config } from '@/helpers/config'

export default {
	name: 'AgentList',
	props: {
		agents: {
			type: Array,
			default: function() {
				return []
			}
		},
		method: { type: Function },
		openModal: { type: Function }
	},
	components: {
		DxDataGrid,
		DxColumn,
		DxGrouping,
		DxGroupPanel,
		DxPager,
		DxPaging,
		DxSearchPanel,
		DxExport,
		DxEditing,
		DxRequiredRule,
		DxEmailRule,
		DxFormItem,
		DxLookup,
		DxButton
	},
	data() {
		return {
			config,
			pageSizes: config.gridItemsPerPage,
			editorOptions: { disabled: true }
		}
	},
	computed: {
		currentDate() {
			return new Date().toISOString().slice(0, 10)
		}
	},
	methods: {
		onInitNewRow(e) {
			e.data.id = null
			e.data.active = true
		},
		onEditorPreparing(e) {
			if (e.dataField == 'email' && e.parentType === 'dataRow' && !e?.row?.isNewRow) {
				e.editorOptions.disabled = true
			}
		},
		onCellPrepared(e) {
			if (e.rowType != 'data' || !e?.row?.isEditing) return

			if (e.column.dataField === 'email' && !e?.row?.isNewRow) {
				e.cellElement.getElementsByTagName('input')[0]?.setAttribute('readonly', true)
			}
		},
		onToolbarPreparing(e) {
			e.toolbarOptions.items.unshift({
				widget: 'dxButton',
				options: {
					icon: 'add',
					onClick: this.addClick
				},
				location: 'after'
			})
		},
		addClick() {
			this.openModal({ active: true, role: 'AGENT' })
		},
		editClick(e) {
			e.event.preventDefault()
			this.openModal(e.row.data)
		}
	}
}
</script>

<template>
	<div>
		<DxDataGrid
			:data-source="agents"
			:remote-operations="false"
			:allow-column-reordering="true"
			:row-alternation-enabled="true"
			selection-mode="single"
			:hover-state-enabled="false"
			:show-borders="false"
			:column-auto-width="true"
			@init-new-row="onInitNewRow"
			@cell-prepared="onCellPrepared"
			@editor-preparing="onEditorPreparing"
			@toolbar-preparing="onToolbarPreparing"
		>
			<DxColumn data-field="id" :allow-editing="false" caption="ID" alignment="left" width="90" cell-template="html-bold-id">
				<DxFormItem :visible="false" />
			</DxColumn>
			<template #html-bold-id="{ data: row }">
				<div class="text-body font-weight-bold" v-html="'#' + (row.value || '')"></div>
			</template>
			<DxColumn data-field="firstName" data-type="string">
				<DxRequiredRule />
			</DxColumn>
			<DxColumn data-field="lastName" data-type="string">
				<DxRequiredRule />
			</DxColumn>
			<DxColumn data-field="email" data-type="string">
				<DxRequiredRule />
				<DxEmailRule message="Email is invalid" />
			</DxColumn>
			<DxColumn data-field="active" />
			<DxColumn data-field="pictureUrl" data-type="string" />

			<DxColumn :allow-grouping="false" :allow-editing="false" data-field="created" data-type="datetime">
				<DxFormItem :visible="false" />
			</DxColumn>
			<DxColumn :allow-grouping="false" :allow-editing="false" data-field="lastLogin" data-type="datetime">
				<DxFormItem :visible="false" />
			</DxColumn>

			<DxColumn data-field="role">
				<DxLookup :data-source="config.roles" />
			</DxColumn>
			<DxColumn type="buttons" :width="60">
				<DxButton icon="edit" hint="Edit" :on-click="editClick" />
			</DxColumn>
			<!-- <DxColumn
			:allow-exporting="false"
			:allow-sorting="false"
			:allow-filtering="false"
			caption="View details"
			cell-template="actionsTemplate"
		/> -->

			<DxEditing :allow-updating="false" :allow-adding="false" mode="popup" />
			<DxExport :enabled="true" :file-name="`${currentDate}-agents-${this.$route.name}`" />
			<DxGroupPanel :visible="false" />
			<DxSearchPanel :width="240" :visible="true" :highlight-case-sensitive="true" />
			<DxGrouping :auto-expand-all="false" />
			<DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-navigation-buttons="true" :show-info="true" />
			<DxPaging :page-size="25" />
			<template #actionsTemplate="{ }">
				<button type="button" class="btn btn-primary btn-sm btn-rounded">
					View Details
				</button>
			</template>
		</DxDataGrid>
	</div>
</template>
