<script>
import RatingGrid from './ratings-grid'
import Multiselect from 'vue-multiselect'
import PageHeader from '@/components/page-header'
import Channel from '@/components/channel'

import {http} from '@/helpers/http'
import appConfig from "@/app.config.json";

export let csatTabs = ['WITH RATING', 'ALL']

export default {
	name: 'CustomerRating',
	page: {
		title: 'Customer rating | Ratings',
		meta: [{ name: 'description', content: appConfig.description }]
	},
	components: { RatingGrid, Multiselect, PageHeader, Channel },
	computed: {
		tabs() {
			return this.tabIds.map((tab, index) => {
				return {
					id: tab,
					title: index >= csatTabs.length ? 'TICKET-' + tab : tab.toUpperCase(),
					badge: this.ticketHeader[tab.toString().toLowerCase()],
					isClosable: index >= csatTabs.length,
					group: index < csatTabs.length
				}
			})
		}
	},
	data() {
		return {
			title: 'Ratings',
			tabIds: [...csatTabs],
			tabIndex: 0,
			showTicketList: true,
			ticketId: null,
			ratings: [],
			ticketHeader: {},
			selectedTab: csatTabs[0],
			items: [
				{
					text: 'Customer rating',
					href: '/'
				},
				{
					text: 'Ratings',
					active: true
				}
			]
		}
	},
	created() {
		this.updateList(this.selectedTab)
	},
	methods: {
		changeTabInfo(tab) {
			this.showTicketList = tab.group
			this.selectedTab = tab.id

			if (!tab.group) {
				this.ticketId = tab.id
				this.tickets = []
			} else {
				this.updateList(tab.id)
			}
		},
		changeBootstrapTabIndex(index) {
			this.$nextTick(() => {
				requestAnimationFrame(() => {
					setTimeout(() => {
						this.tabIndex = index
					}, 10)
				})
			})
		},
		addPaddingClass() {
			return this.showTicketList ? "p-3" : "p-0"
		},
		addTab(id) {
			const i = this.tabIds.indexOf(id)
			if (i < 0) {
				this.tabIds.push(id)
			}
			this.openTab(id)
		},
		openTab(id) {
			const index = this.tabs.findIndex((x) => x.id === id)
			this.changeTabInfo(this.tabs[index])
			this.changeBootstrapTabIndex(index)
		},
		updateBrandSelection() {
			if (this.tabIndex < csatTabs.length) {
				this.updateList()
			} else {
				this.getHeaders()
			}
		},
		async updateList(type = this.selectedTab) {
			let response
			let allEntries = type === 'ALL'
			try {
				response = await http.get('csat/list/' + allEntries).json()
			} catch (e) {
				// Show error
			}
			this.ratings = response
		}
	}
}
</script>

<template>
	<div>
		<PageHeader :title="title" :items="items" />
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<b-tabs nav-class="nav-tabs-custom"
								:content-class="`${addPaddingClass()} text-muted`" v-model="tabIndex">
							<b-tab
								v-for="(tab, index) in tabs"
								:key="index"
								@click="changeTabInfo(tab)">
								<template #title>
									<span>
										{{ tab.title }}
									</span>
								</template>
							</b-tab>
						</b-tabs>
						<div class="row">
							<div class="col-sm-12">
								<RatingGrid v-if="showTicketList" :dataList="ratings"  :name="selectedTab" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss"></style>
