<script>
import * as dayjs from 'dayjs'
import isToday from 'dayjs/esm/plugin/isToday'
import Attachment from '@/components/attachment'

dayjs.extend(isToday)

export default {
	name: 'ChatMessage',
	components: { Attachment },
	props: {
		message: { type: Object, required: true },
		likeClicked: { type: Function },
		hideClicked: { type: Function },
		deleteClicked: { type: Function }
	},
	data() {
		return {
			selectedImageUrl: null
		}
	},
	computed: {
		id() {
			return this._uid.toString()
		},
		isRightMessage() {
			return (
				this.message.direction === 'OUTBOUND'
			)
		},
		dateTime() {
			const date = dayjs(this.message.created)
			return date.isToday() ? date.format('HH:mm:ss') : date.format('YYYY-MM-DD HH:mm:ss')
		},
		isChannel() {
			return this.message?.messageType === 'POST'
		},
		hiddenClasses() {
			return {
				'spinner-border': this.message.isHideLoading,
				'mdi-eye-off-outline': !this.message.isHideLoading && this.message.hidden,
				'mdi-eye-outline': !this.message.isHideLoading && !this.message.hidden
			}
		}
	},
	methods: {
		getFirstLetter(name) {
			return name?.charAt(0)?.toUpperCase();
		},
		openModal(image) {
			this.selectedImageUrl = image
			this.$refs.imageModal.show()
		},
	},
};
</script>
<template>
	<div class="conversation-list" :class="{ 'right': isRightMessage }">
		<div class="image mx-1" :id="id" :title="message.name">
			<template v-if="message.image">
				<!-- <img src="@/assets/images/users/avatar-1.jpg" class="avatar-xs rounded-circle" alt width="40" height="40"/> -->
				<img
					:src="message.image"
					class="avatar-xs rounded-circle"
					width="40"
					height="40"
				/>
			</template>
			<template v-else>
				<div class="rounded-circle avatar-xs bg-light text-center">
					{{ getFirstLetter(message.name) }}
				</div>
			</template>
		</div>
		<!-- <b-tooltip :target="id" triggers="hover">
			{{ message.name }}
		</b-tooltip> -->
		<div>
			<p class="chat-time mb-0">
				{{  message.name }}
				<i class="mdi mdi-clock-outline mx-1"></i>
				{{ dateTime }}
			</p>
			<div class="c-content">
				<div v-if="message.message" class="text-wrap">{{ message.message }}</div>
				<div class="attachments">
					<div class="row">
						<div class="col-4" :key="index" v-for="(attachment, index) in message.attachments">
							<Attachment class="attachment" :attachment="attachment" :itemClick="openModal" :groupId="message.id" />
						</div>
					</div>
				</div>
				<div class="buttons" v-if="isChannel">
					<button	class="btn chat-button p-1" v-b-tooltip.hover="'Like'" @click="likeClicked(message)">
						<i class="mdi px-1" :class="[{ liked: message.liked }, message.isLikeLoading ? 'spinner-border' : 'mdi-thumb-up-outline']">
						</i>
					</button>
					<button	class="btn chat-button p-1" v-b-tooltip.hover="'Delete'" @click="deleteClicked(message)">
						<i class="mdi px-1" :class="[message.isDeleteLoading ? 'spinner-border' : 'mdi-trash-can-outline']"></i>
					</button>
					<button	class="btn chat-button p-1" v-b-tooltip.hover="'Hide'" @click="hideClicked(message)">
						<i class="mdi px-1" :class="hiddenClasses"></i>
					</button>
					<button	class="btn chat-button p-1" v-b-tooltip.hover="'Reply'">
						<i class="mdi mdi-replay px-1"></i>
					</button>
				</div>
			</div>
		</div>
		<b-dropdown variant="white">
			<template v-slot:button-content>
				<i class="mdi mdi-dots-vertical fs-1rem"></i>
			</template>
			<b-dropdown-item>Copy</b-dropdown-item>
			<b-dropdown-item>Save</b-dropdown-item>
			<b-dropdown-item>Forward</b-dropdown-item>
			<b-dropdown-item>Delete</b-dropdown-item>
		</b-dropdown>
		<b-modal
			ref="imageModal"
			size="lg"
			body-class="p-1"
			modal-class="image-modal"
			title="Image"
			hide-footer
		>
			<div class="">
				<img :src="selectedImageUrl" />
			</div>
		</b-modal>
	</div>
</template>
<style lang="scss">
.c-content {

	min-width: 250px;
	max-width: 550px;

	//max-width: 570px;
	//min-width: 420px;
	//max-width: 570px;
	//min-width: 420px;
	//color: #959595;
	//padding: 19px 30px 35px;
	//margin-top: 5px;
	//font-size: 14px;
	//font-weight: 700;
	//position: relative;
	//float: right;
	//word-break: break-word;
	//text-align: left;

	box-shadow:0 2px 4px rgba(3,27,78,.06);
	-webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
	-moz-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);

	//border:0px solid #e5e8ed;

	//-webkit-box-shadow: 0 0 9px 0 rgba(140,140,140,.13);
	//-moz-box-shadow: 0 0 9px 0 rgba(140,140,140,.13);
	//box-shadow: 0 0 9px 0 rgba(140,140,140,.13);
	//-webkit-border-radius: 8px;
	//-moz-border-radius: 8px;
	//-ms-border-radius: 8px;
	//border-radius: 8px;
}

	.image-modal .modal-dialog {
		width: fit-content;
		
		img {
			width: 100%;
		}
	}

	.chat-button.btn {
		color: #b4b4b4;
		font-size: 16px;
		width: 35px;
    	height: 35px;

		&:hover {
			color: #000000;
		}

		.liked:not(.spinner-border), .mdi-eye-outline {
			background: #dadada;
			border-radius: 50px;
		}

		.spinner-border {
			width: 0.8rem;
			height: 0.8rem;
			border-width: 0.15rem;
		}
	}

	.chat-button .spinner-border {
		vertical-align: middle;
		margin-top: -2px;
	}
</style>
