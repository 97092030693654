<script>
import {
	DxDataGrid,
	DxColumn,
	DxGrouping,
	DxGroupPanel,
	DxPager,
	DxPaging,
	DxSearchPanel,
} from 'devextreme-vue/data-grid'
import 'devextreme/data/odata/store'
import { config } from '@/helpers/config'
import Channel from '@/components/channel'

let collapsed = false

export default {
	name: 'RatingGrid',
	props: {
		dataList: {
			type: Array,
			default: function() {
				return []
			}
		},
		name: { type: String },
		method: { type: Function }
	},
	components: {
		DxDataGrid,
		DxColumn,
		DxGrouping,
		DxGroupPanel,
		DxPager,
		DxPaging,
		DxSearchPanel,
		Channel
	},
	data() {
		return {
			config,
			pageSizes: [10, 25, 50, 100],
			onContentReady: function(e) {
				if (!collapsed) {
					e.component.expandRow(['EnviroCare'])
					collapsed = true
				}
			}
		}
	},
	computed: {
		currentDate() {
			return new Date().toISOString().slice(0, 10)
		}
	},
	methods: {
		openTicketInNewTab(ticketId) {
			let routeData = this.$router.resolve({
				name: 'ticket',
				params: { id: ticketId },
			})
			window.open(routeData.href, '_blank')
		},
	}
}
</script>

<template>
	<DxDataGrid
		:data-source="dataList"
		:remote-operations="false"
		:allow-column-reordering="true"
		:row-alternation-enabled="true"
		selection-mode="single"
		:hover-state-enabled="false"
		:show-borders="false"
		:column-auto-width="true"
		@content-ready="onContentReady"
	>
		<DxColumn
			data-field="id"
			caption="Ticket ID"
			alignment="left"
			width="90"
			cell-template="html-bold-id"
		/>
		<template #html-bold-id="{ data: row }">
		<div class="text-body font-weight-bold" v-html="'#' + row.value"></div>
	</template>
		<DxColumn data-field="sent" data-type="datetime" />
		<DxColumn data-field="received" data-type="datetime" />
		<DxColumn data-field="score" data-type="integer" />
		<DxColumn
			:allow-exporting="false"
			:allow-sorting="false"
			:allow-filtering="false"
			caption="View details"
			cell-template="actionsTemplate"
		/>
		<DxGrouping :auto-expand-all="false" />
		<DxPager
			:visible="true"
			:allowed-page-sizes="pageSizes"
			:show-page-size-selector="true"
			:show-navigation-buttons="true"
			:show-info="true"
		/>
		<DxPaging :page-size="10" />
		<div slot="actionsTemplate" slot-scope="{ data }" class="buttons">
			<button
				type="button"
				@click="openTicketInNewTab(data.data.ticketId)">
				<Channel :channelId="data.data.channelId" :hide-text="true" />
				Ticket #{{data.data.ticketId}}
			</button>
		</div>
	</DxDataGrid>
</template>
