<script>
import simplebar from 'simplebar-vue';

import ChatMessage from './ChatMessage';
import AddChatMessage from './AddChatMessage'

import { http } from '@/helpers/http';

export default {
	name: 'Chat',
	components: { simplebar, ChatMessage, AddChatMessage },
	props: {
		chatData: {type: Array, required: true},
		user: {type: Object, required: true},
		postUrl: {type: String, default: null},
		showHeader: { type: Boolean, required: false, default: true },
		addChatMessage: { type: Function }
	},
	data() {
		return {
			title: 'Chat'
		};
	},
	watch: {
		chatData() {
			this.scrollToEnd()
		},
	},
	methods: {
		scrollToEnd() {
			this.$nextTick().then(() => {
				var container = this.$refs.chatsimplebar.SimpleBar.getScrollElement();
				container.scrollTop = container.scrollHeight;
			});
		},
		async likeClicked(message) {
			let like = !message.liked
			try {
				await http.post(`message/like/${message.id}/${like}`)
			} catch (e) {
				// Show error
				return
			}
			this.$set(message, 'isLikeLoading', true)
		},
		async deleteClicked(message) {
			try {
				await http.delete(`message/delete/${message.id}`)
			} catch (e) {
				// Show error
				return
			}
			this.$set(message, 'isDeleteLoading', true)
		},
		async hideClicked(message) {
			let hidden = !message.hidden
			try {
				await http.post(`message/hide/${message.id}/${hidden}`)
			} catch (e) {
				// Show error
				return
			}
			this.$set(message, 'isHideLoading', true)
		},
		// addMessage(message) {
		// 	const currentDate = new Date();
		// 	this.chatData.push({
		// 		direction: 'OUTBOUND',
		// 		name: 'Henry Wells',
		// 		message,
		// 		time:
		// 			currentDate.getHours() + ':' + currentDate.getMinutes()
		// 	});
		// 	this.scrollToEnd()
		// }
	}
};
</script>

<template>
	<div class="w-100 user-chat">
		<div class="card">
			<!-- Header -->
			<div class="p-3 border-bottom" v-if="showHeader && user">
				<div class="row">
					<div class="col-md-4 col-9">
						<h5 class="font-size-15 mb-1">{{ user.name }}</h5>
						<p class="text-muted mb-0">
							<i class="mdi mdi-circle text-success align-middle mr-1"></i>
							{{ user.status }}
						</p>
					</div>
					<div class="col-md-8 col-3">
						<ul class="list-inline user-chat-nav text-right mb-0">
<!--							<li class="list-inline-item d-none d-sm-inline-block">-->
<!--								<b-dropdown-->
<!--									menu-class="dropdown-menu-md"-->
<!--									variant="white"-->
<!--									right-->
<!--									toggle-class="nav-btn"-->
<!--								>-->
<!--									<template v-slot:button-content>-->
<!--										<i class="bx bx-search-alt-2"></i>-->
<!--									</template>-->
<!--									<form class="p-3">-->
<!--										<div class="form-group m-0">-->
<!--											<div class="input-group">-->
<!--												<input-->
<!--													type="text"-->
<!--													class="form-control"-->
<!--													placeholder="Search ..."-->
<!--													aria-label="Recipient's username"-->
<!--												/>-->
<!--												<div class="input-group-append">-->
<!--													<button-->
<!--														class="btn btn-primary"-->
<!--														type="submit"-->
<!--													>-->
<!--														<i class="mdi mdi-magnify"></i>-->
<!--													</button>-->
<!--												</div>-->
<!--											</div>-->
<!--										</div>-->
<!--									</form>-->
<!--								</b-dropdown>-->
<!--							</li>-->
<!--							<li class="list-inline-item d-none d-sm-inline-block">-->
<!--								<b-dropdown-->
<!--									toggle-class="nav-btn"-->
<!--									right-->
<!--									variant="white"-->
<!--								>-->
<!--									<template v-slot:button-content>-->
<!--										<i class="bx bx-cog"></i>-->
<!--									</template>-->
<!--									<b-dropdown-item>View Profile</b-dropdown-item>-->
<!--									<b-dropdown-item>Clear chat</b-dropdown-item>-->
<!--									<b-dropdown-item>Muted</b-dropdown-item>-->
<!--									<b-dropdown-item>Delete</b-dropdown-item>-->
<!--								</b-dropdown>-->
<!--							</li>-->

							<li class="list-inline-item">
								<b-dropdown
									right
									variant="white"
								>
									<template v-slot:button-content>
										<i class="mdi mdi-dots-vertical fs-1rem" v-b-tooltip.hover title="More"></i>
									</template>
									<b-dropdown-item>Block user</b-dropdown-item>
								</b-dropdown>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- Chat body -->
			<div class="chat-users">
				<div class="chat-conversation p-3">
					<simplebar
						ref="chatsimplebar"
						style="max-height: 500px"
					>
						<div>
							<iframe
								v-if="postUrl"
								:src="postUrl"
								width="350"
								height="300"
								scrolling="auto"
								frameborder="0"
								allowTransparency="true"
								allow="encrypted-media"
							></iframe>
						</div>
						<ul class="list-unstyled">
							<li>
								<div class="chat-day-title">
									<span class="title">Today</span>
								</div>
							</li>
							<li
								v-for="(data, index) of chatData"
								:key="index"
							>
								<ChatMessage 
									:message="data"
									:likeClicked="likeClicked"
									:hideClicked="hideClicked"
									:deleteClicked="deleteClicked" />
							</li>
						</ul>
					</simplebar>
				</div>
				<!-- New Message -->
				<AddChatMessage :addChatMessage="addChatMessage" />
			</div>
		</div>
	</div>
	<!-- end row -->
</template>
