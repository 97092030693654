<script>
export default {
	components: {},
	props: {
		title: {
			type: String,
			default: ''
		},
		isHtml: {
			type: Boolean,
			default: false
		},
		items: {
			type: Array,
			default: () => {
				return []
			}
		}
	}
}
</script>

<template>
	<!-- start page title -->
	<div class="row">
		<div class="col-12">
			<div class="page-title-box d-flex align-items-center justify-content-between">
				<h3 v-if="isHtml" v-html="title"></h3>
				<h3 v-else class="mb-0 font-size-18">{{ title }}</h3>

				<div class="page-title-right">
					<b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
				</div>
			</div>
		</div>
	</div>
	<!-- end page title -->
</template>
