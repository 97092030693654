<script>
import appConfig from '@/app.config'

export default {
	name: 'app',
	components: {},
	page: {
		// All subcomponent titles will be injected into this template.
		titleTemplate(title) {
			title = typeof title === 'function' ? title(this.$store) : title
			return title ? `${title} | ${appConfig.title}` : appConfig.title
		}
	},
	mounted() {},
	watch: {
		/**
		 * Clear the alert message on route change
		 */
		// eslint-disable-next-line no-unused-vars
		$route(to, from) {}
	}
}
</script>

<template>
	<div id="app">
		<RouterView />
	</div>
</template>
