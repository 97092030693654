<script>
import { config } from "@/helpers/config";

export default {
	name: 'Channel',
	props: {
		channelId: { type: Number, required: true },
		attachmentCount: { type: Number, required: false, default: 0 },
		hideText: { type: Boolean, required: false },
	},
	computed: {
		getChannelName() {
			return this.hideText ? "" : config.channels[this.channelId]
		},
	},
}
</script>

<template>
	<span>
		<i v-if="channelId === 1" class='bx-fw bx bx-mail-send btn-channel-email'></i>
		<i v-else-if="channelId === 2 || channelId === 3" class='bx-fw bx bxl-facebook-circle btn-channel-facebook'></i>
		<i v-else-if="channelId === 4" class='bx-fw bx bxs-chat btn-channel-livechat'></i>
		{{ getChannelName }}
		<i v-if="channelId === 1 && attachmentCount > 0" class='bx-fw bx bx-paperclip'></i>
	</span>
</template>
