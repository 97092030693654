<script>
import * as dayjs from 'dayjs'
import isToday from 'dayjs/esm/plugin/isToday'
import Attachment from '@/components/attachment'

dayjs.extend(isToday);

export default {
	name: 'EmailCard',
	components: { Attachment },
	props: {
		email: { type: Object, required: true },
		id: { type: Number, required: true },
		replayClick: { type: Function, default: () => null },
	},
	data() {
		return {
			selectedImageUrl: null,
			visible: this.email.open
		}
	},
	computed: {
		dateTime() {
			const date = dayjs(this.email.created)
			return date.isToday() ? date.format('HH:mm') : date.format('MMMM. d HH:mm')
		},
		popoverId() {
			return `popover_${ this.id }`;
		},
		fromList() {
			return `${ this.email?.from?.name } <${ this.email?.from?.email }>`
		},
		toList() {
			return this.email?.toList.map(x => x.email).join(', ')
		},
		ccList() {
			return this.email?.ccList.map(x => x.email).join(', ')
		}
	},
	methods: {
		getFirstLetter(name) {
			return name?.charAt(0)?.toUpperCase();
		},
		openModal(image) {
			this.selectedImageUrl = image
			this.$refs.imageModal.show()
		},
		headerClick() {
			this.email.open = !this.email.open
			if (this.email.open) {
				this.visible = true
			}
		},
		replay() {
			const data = {
				subject: this.email?.subject,
				message: this.email?.message,
				// message: `<br /><br /><p>Recieved: ${this.dateTime}<br />Sender: ${this.email?.name}</p><blockquote>${this.email?.message}</blockquote>`,
				to: ''
			}
			this.replayClick(data)
		}
	},
};
</script>
<template>
	<b-card no-body class="mb-1">
		<b-card-header
			header-tag="header"
			class="p-1"
			style="background: white"
			role="tab"
			@click="headerClick"
		>
			<div class="media m-2">
				<div class="image mr-2">
					<template v-if="email.image">
						<img
							class="rounded-circle avatar-sm"
							:src="email.image"
						/>
					</template>
					<template v-else>
						<div class="rounded-circle avatar-sm bg-light text-center">
							{{ getFirstLetter(email.name) }}
						</div>
					</template>
				</div>

				<div class="media-body">
					<h5 class="font-size-14 mt-1">{{ email.name }}</h5>
					<div v-if="email.open">Recievers: {{ toList }}
						<button :id="popoverId" type="button" class="btn" @click.stop tabindex="0">
							<i class="mdi mdi-arrow-down-drop-circle-outline fs-1rem mdi-middle" v-b-tooltip.hover title="Details"></i>
						</button>
						<b-popover custom-class="email" :target="popoverId" triggers="focus" placement="rightbottom">
							<div class="row mb-1">
								<div class="col email-max">FROM:</div>
								<div class="col">{{ fromList }}</div>
							</div>
							<div class="row mb-1">
								<div class="col email-max">TO:</div>
								<div class="col">{{ toList }}</div>
							</div>
							<div class="row mb-1">
								<div class="col email-max">CC:</div>
								<div class="col">{{ ccList }}</div>
							</div>
							<div class="row mb-1">
								<div class="col email-max">Date:</div>
								<div class="col">{{ dateTime }}</div>
							</div>
						</b-popover>
					</div>
					<h6 class="text-muted">{{ email.subject }}</h6>
				</div>

				<div class="media-right">
					<span class="py-2 mb-0">
						{{ dateTime }}
					</span>
					<button type="button" class="btn" @click.stop="replay">
						<i class="mdi mdi-reply fs-1rem" v-b-tooltip.hover title="Reply"></i>
					</button>
					<b-dropdown right variant="white">
						<template v-slot:button-content>
							<i class="mdi mdi-dots-vertical fs-1rem" v-b-tooltip.hover title="More"></i>
						</template>
						<b-dropdown-item @click.stop="replay">Reply all</b-dropdown-item>
						<b-dropdown-item @click.stop>Forward</b-dropdown-item>
					</b-dropdown>
				</div>
			</div>
		</b-card-header>
		<b-collapse
			v-model="email.open"
			role="tabpanel"
		>
			<b-card-body>
				<div class="email-message" v-html="email.message"></div>
				<hr />
				<div class="row" v-if="visible">
					<div class="col-xl-2 col-4" :key="index" v-for="(attachment, index) in email.attachments">
						<div class="card">
							<Attachment :attachment="attachment" :showDownload="true" :itemClick="openModal" :groupId="email.id" />
						</div>
					</div>
				</div>
			</b-card-body>
		</b-collapse>
		<b-modal
			ref="imageModal"
			size="lg"
			body-class="p-1"
			modal-class="image-modal"
			title="Image"
			hide-footer
		>
			<div class="">
				<img :src="selectedImageUrl" />
			</div>
		</b-modal>
	</b-card>
</template>

<style lang="scss">

.email-message {
	// white-space: pre;
	overflow: auto;
}

.media-right {
	flex: 1;
	justify-content: space-between;
	text-align: right;
}

.email.popover {
    max-width: 350px;
}

.col.email-max {
	max-width: 65px;
}

</style>
