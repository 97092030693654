<script>
import { Client } from '@stomp/stompjs'
import PageHeader from '@/components/page-header'

import { http } from '@/helpers/http'
import { config } from '@/helpers/config'
import { required } from 'vuelidate/lib/validators'
import { alert } from '@/helpers/alert'
import Chat from './chat/Chat'
import Multiselect from 'vue-multiselect'
import Status from '@/components/status'
import EmailPanel from './email/components/email-panel'

export default {
	name: 'TicketDetail',
	props: {
		ticketId: { type: Number, required: true }
	},
	components: { PageHeader, Chat, Multiselect, EmailPanel, Status },
	computed: {
		user() {
			return {
				name: this.ticketInfo?.client?.fullName,
				status: 'Active'
			}
		},
		ticketBrandId() {
			return this.ticketInfo?.brand?.id
		},
		title() {
			return `Ticket <b>#${this.ticketId}</b>&numsp;`
		},
		isSolved() {
			return this.ticketInfo?.ticketStatus === 'SOLVED'
		},
		isClosed() {
			return this.ticketInfo?.ticketStatus === 'CLOSED'
		},
		isNew() {
			return this.ticketInfo?.ticketStatus === 'NEW'
		},
		canSolve() {
			return this.ticketInfo?.ticketStatus === 'OPEN' || this.ticketInfo?.ticketStatus === 'PENDING'
		},
		isEmail() {
			return this.ticketInfo?.channelId === 1
		},
		isFBFeed() {
			return this.ticketInfo?.channelId === 3
		},
		isCategoryEnabled() {
			return JSON.parse(localStorage.getItem(config.storage.CATEGORIES_ENABLED)) ?? false
		},
		userList() {
			return JSON.parse(localStorage.getItem(config.storage.AGENTS))
		}
	},
	data() {
		return {
			config,
			client: null,
			subscriptions: [],
			ticketInfo: null,
			historicalTickets: 0,
			messages: [],
			ticketCategories: [],
			ticketTypes: [],
			loading: true
		}
	},
	watch: {
		ticketId() {
			this.loading = true
			this.messages = []
			this.ticketInfo = null

			this.webSocketClose()
			this.getMessages()
			this.getTicketInfo()
		},
		ticketBrandId() {
			this.getTicketCategories()
		}
	},
	created() {
		this.getMessages()
		this.getTicketInfo()
	},
	destroyed() {
		this.webSocketClose()
	},
	validations: {
		ticketInfo: {
			category: { required },
			type: { required },
			agent: {}
		}
	},
	methods: {
		async getTicketInfo(reConnect = true) {
			if (!this.ticketId) {
				return
			}
			let response
			try {
				response = await http.get(`ticket/detail/${this.ticketId}`).json()
			} catch (e) {
				// Show error
				return
			} finally {
				this.loading = false
			}
			this.ticketInfo = response

			if (reConnect) {
				this.webSocketConnect()
			}
		},
		getTicketCategories() {
			if (!this.ticketBrandId) {
				return
			}
			const ticketCategories = JSON.parse(
				localStorage.getItem(config.storage.CATEGORIES))

			this.ticketCategories = ticketCategories.filter((category) => !category.brandId || category.brandId === this.ticketBrandId)
		},
		sendMessage(message) {
			this.client.publish({
				destination: `/app/message/${this.ticketId}/post`,
				body: JSON.stringify({
					ticketId: this.ticketId,
					message: message?.trim(),
					brandId: this.ticketInfo?.brand?.id
				})
			})
		},
		webSocketConnect() {
			this.client = new Client({
				brokerURL: `${config.isSecure ? 'wss' : 'ws'}://${window.location.host}/stomp`,
				// debug(str) {
				// 	console.log(str)
				// },
				onStompError(frame) {
					// Will be invoked in case of error encountered at Broker
					// Bad login/passcode typically will cause an error
					// Complaint brokers will set `message` header with a brief message. Body may contain details.
					// Compliant brokers will terminate the connection after any error
					console.log('Broker reported error: ' + frame.headers['message'])
					console.log('Additional details: ' + frame.body)
				},
				onWebsocketClose() {
					console.log('onWebsocketClose')
					this.webSocketClose()
				},
				onDisconnect(frame) {
					console.log('onDisconnect', frame)
				},
				heartbeatIncoming: 40000,
				heartbeatOutgoing: 40000
			})
			this.client.activate()

			this.client.onConnect = (frame) => {
				console.log('onConnect', frame)
				this.subscriptions = []
				// Do something, all subscribes must be done is this callback
				// This is needed because this will be executed after a (re)connect
				// this.subscriptions.push(this.client.subscribe(`/app/message/responses/${this.ticketId}`, this.handleTicketMessageResponses))

				this.subscriptions.push(this.client.subscribe(`/message/response/${this.ticketId}`, this.handleTicketMessageResponses))
				if (this.isFBFeed) {
					this.subscriptions.push(this.client.subscribe(`/message/actions/response/${this.ticketId}`, this.handleActionResponses))
				}
			}
		},
		webSocketClose() {
			this.subscriptions.forEach((subscription) => {
				subscription?.unsubscribe()
			})
			this.subscriptions = []
			this.client?.deactivate()
			this.client = null
		},
		async ticketAction(ticketId, action) {
			try {
				await http.post('ticket/' + action, {
					json: {
						id: ticketId
					}
				})
			} catch (e) {
				return
			}
			// this.openTab(ticketId)
			this.loading = true
			this.getTicketInfo(false)
			// show.success(`Ticket ${ticketId} has been claimed successfully`)
		},
		handleTicketMessageResponses(response) {
			if (response?.body) {
				const messages = JSON.parse(response.body)
				this.messages.push(...messages)
			}
		},
		ticketCategoryChange(data) {
			this.$set(this.ticketInfo, 'type', null)
			this.ticketTypes = data.types
		},
		async getMessages() {
			try {
				const responses = await http.get(`message/responses/${this.ticketId}`).json()
				const response = responses[responses.length - 1]
				if (response) {
					response.open = true
				}
				this.messages = responses
			} catch (e) {
				return
			}
		},
		async saveTicket() {
			try {
				await http.post('ticket/save', {
					json: {
						id: this.ticketInfo.id,
						categoryId: this.ticketInfo?.category?.id,
						typeId: this.ticketInfo?.type?.id,
						agentId: this.ticketInfo?.agent?.id
					}
				})
			} catch (e) {
				return alert.error(e.message)
			}
		},
		handleActionResponses(response) {
			if (response?.body) {
				const actionResponse = JSON.parse(response.body)
				const message = this.messages.find((x) => x.id === actionResponse.ticketMessageId)
				switch (actionResponse.messageAction) {
					case 'LIKE':
						message.liked = actionResponse.like
						this.$set(message, 'isLikeLoading', false)
						break
					case 'HIDE':
						message.hidden = actionResponse.hidden
						this.$set(message, 'isHideLoading', false)
						break
					case 'DELETE':
						message.deleted = actionResponse.delete
						this.$set(message, 'isDeleteLoading', false)
						break

					default:
						break
				}
			}
		}
	}
}
</script>
<template>
	<div col-sm-12>
		<span v-if="loading" class="fa fa-spinner fa-pulse fa-fw m-2 font-size-24" />
		<div class="row" v-else-if="!ticketInfo">
			<div class="col-sm-12">
				<h2>Error</h2>
				<p>Ticket {{ ticketId }} was not found.</p>
			</div>
		</div>
		<template v-else>
			<div class="row">
				<div class="col-lg-3 col-md-5 py-3">
					<div class="row" >
						<div class="col-sm-12">
							<div class="d-flex">
								<PageHeader :title="title" :is-html="true" />
								<Status :status="ticketInfo.ticketStatus" />
							</div>
						</div>
					</div>
					<div class="row" v-if="isCategoryEnabled">
						<div class="col">
							<b-form-group
								label="Category"
								label-for="categories-field"
								:class="{ 'is-unsaved': $v.ticketInfo.category.$dirty }"
								:state="!$v.ticketInfo.category.$error"
								:valid-feedback="$v.ticketInfo.category.$dirty ? 'Unsaved changes' : ''"
								:invalid-feedback="$v.ticketInfo.category.$invalid ? 'Field is required' : ''"
							>
								<multiselect
									id="categories-field"
									v-model="$v.ticketInfo.category.$model"
									:options="ticketCategories"
									:searchable="true"
									:allow-empty="false"
									:close-on-select="true"
									:show-labels="false"
									name="ticketCategory"
									placeholder="Select a ticket category"
									label="name"
									track-by="id"
									@select="ticketCategoryChange"
								/>
							</b-form-group>
						</div>
						<div class="col" v-if="ticketTypes.length > 0">
							<b-form-group
								label="Issue"
								label-for="types-field"
								:class="{ 'is-unsaved': $v.ticketInfo.type.$dirty }"
								:state="!$v.ticketInfo.type.$invalid"
								:valid-feedback="$v.ticketInfo.type.$dirty ? 'Unsaved changes' : ''"
								:invalid-feedback="$v.ticketInfo.type.$invalid ? 'Field is required' : ''"
							>
								<multiselect
									id="types-field"
									v-model="$v.ticketInfo.type.$model"
									:options="ticketTypes"
									:searchable="true"
									:allow-empty="false"
									:close-on-select="true"
									:show-labels="false"
									name="ticketCategoryType"
									placeholder="Select a ticket issue"
									label="name"
									track-by="id"
								/>
							</b-form-group>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12">
							<b-form-group
								label="Ticket assignee"
								label-for="assignee-field"
								:class="{ 'is-unsaved': $v.ticketInfo.agent.$dirty }"
								:state="!$v.ticketInfo.agent.$invalid"
								:valid-feedback="$v.ticketInfo.agent.$dirty ? 'Unsaved changes' : ''"
							>
								<multiselect
									id="assignee-field"
									v-model="$v.ticketInfo.agent.$model"
									:options="userList"
									:searchable="true"
									:allow-empty="false"
									:close-on-select="true"
									:show-labels="false"
									placeholder="Select a assignee"
									label="fullName"
									track-by="id"
								/>
							</b-form-group>
						</div>
					</div>
					<b-button hidden="hidden" variant="success" :disabled="historicalTickets === 0" class="ml-3">
						<span class="fa fa-history mr-2" />
						History ({{ historicalTickets }})
					</b-button>
					<template v-if="isNew">
						<b-button variant="success" class="btn-label float-right" @click="ticketAction(ticketInfo.id, 'claim')">
							<i class="bx bxs-coupon label-icon"></i> Claim ticket
						</b-button>
					</template>
					<template v-else-if="canSolve">
						<b-button variant="success" class="btn-label float-right" @click="ticketAction(ticketInfo.id, 'solve')">
							<i class="bx bxs-coupon label-icon"></i> Solve ticket
						</b-button>
					</template>
					<template v-else-if="isSolved">
						<b-button variant="success" class="btn-label float-right" @click="ticketAction(ticketInfo.id, 'open')"> <i class="bx bxs-coupon label-icon"></i> Open ticket </b-button>
					</template>
					<b-button v-if="canSolve" variant="primary" class="btn-label" @click="saveTicket">
						<i class="bx bx-save label-icon"></i> Save ticket
					</b-button>
				</div>
				<div class="col-lg-9 col-md-7 pt-3">
					<EmailPanel v-if="isEmail" :messages="messages" />
					<Chat v-else :chatData="messages" :user="user" :addChatMessage="sendMessage" :postUrl="ticketInfo.facebookPostUrl" />
				</div>
			</div>
		</template>
	</div>
</template>
<style lang="scss">
@import 'src/design/color-variables';

.ticket-controls {
	background: #fff;
	white-space: nowrap;
	color: #525252;
	font-size: 14px;
	overflow: hidden;
}
.is-unsaved {
	.valid-feedback {
		color: $yellow;
	}

	.multiselect__tags {
		border: 1px solid $yellow;
	}
}

.is-invalid .multiselect__tags {
	border-color: $red;
}
</style>
