<script>
export default {
	name: 'Status',
	props: {
		status: { type: String, required: true },
		unreadMessages: { type: Number, required: false }
	}
}
</script>

<template>
	<span>
		<span
			class="badge badge-pill badge-soft-success font-size-12"
			:class="{
				'badge-soft-danger': `${status}` === 'CLOSED',
				'badge-soft-warning': `${status}` === 'NEW'
			}"
			>{{ status }}
		</span>
		<b-badge v-if="unreadMessages > 0" variant="danger">{{ unreadMessages }}</b-badge>
	</span>
</template>
