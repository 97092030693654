<script>
const prefixHttp = process.env.NODE_ENV === 'production' ? '/' : '/api'
import PhotoSwipe from 'photoswipe/dist/photoswipe'
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import createPreviewDirective from "@chantouchsek/vue-photoswipe";


export default {
	name: 'Attachment',
	props: {
		attachment: { type: Object, required: true },
		itemClick: { type: Function, default: () => null },
		groupId: { type: Number, default: Math.floor(Math.random() * 10000000) },
		showDownload: { type: Boolean, required: false, default: false }
	},
	directives: {
		preview: createPreviewDirective({bgOpacity: 0.4}, PhotoSwipe, PhotoSwipeUI)
	},
	components: {},
	data() {
		return {}
	},
	computed: {
		getPictureUrl() {
			return this.attachment?.url ?? 'data:image/jpg;base64,' + this.attachment?.file
		},
		getUrl() {
			if (this.attachment?.url.startsWith('/')) {
				return prefixHttp + this.attachment?.url	
			}
			return this.attachment?.url
		},
		getTitle() {
			return this.attachment?.title ?? 'file'
		},
		isImage() {
			return this.attachment?.type === 'IMAGE'
		}
	},
	methods: {}
}
</script>

<template>
	<div>
		<div class="attachment">
			<img
				v-if="isImage"
				v-preview="{ name: groupId }"
				class="image"
				:src="getUrl"
				alt="Picture"
			/>
			<template v-else>
				<div class="file">
					<i class="fas fa-file text-center icon"></i>
					<p class="text text-center p-1">{{ getTitle }}</p>
				</div>
			</template>
			<div class="download text-center" v-if="showDownload">
				<a :download="getTitle" :href="getUrl" class="btn btn-outline-secondary" role="button">
					<i class="mdi mdi-download"></i>
					Download</a>
			</div>
		</div>

	</div>
</template>

<style lang="scss">
.attachment {
	height: 100px;

	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.file {
		height: 100%;

		.icon {
			font-size: 3rem;
			width: 100%;
		}

		.text {
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	.download {
		display: none;
		position: relative;
		top: -40px;
	}

	&:hover {
		.image, .file {
			opacity: 0.2;
		}
		.download {
			display: block;
		}
	}
}
</style>
