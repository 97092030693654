<script>
import { required } from 'vuelidate/lib/validators';

import 'emoji-mart-vue-fast/css/emoji-mart.css'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import facebookData from 'emoji-mart-vue-fast/data/facebook.json'
import ClickOutside from 'vue-click-outside'


export default {
	name: 'AddChatMessage',
	components: { Picker },
	props: {
		addChatMessage: { type: Function }
	},
	data() {
		return {
			submitted: false,
			message: '',
			showModal: false,
			emojiData: new EmojiIndex(facebookData, { recentLength: 9 })
		};
	},
	computed: {	},
	directives: {
		ClickOutside
	},
	validations: {
		message: { required }
	},
	methods: {
		closeEmoji() {
			this.showModal = false
		},
		formSubmit(e) {
			this.submitted = true

			// stop here if form is invalid
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			} else {
				this.addChatMessage(this.message)
			}
			this.submitted = false;
			this.message = '';
		},
		append(emoji) {
			const textarea = this.$refs.textarea
			let cursorPos = textarea.selectionStart
			if (!cursorPos) {
				this.message += emoji.native
				return
			}

			const before = textarea.value.substr(0, cursorPos)
			const after = textarea.value.substr(cursorPos)

			this.message = `${before}${emoji.native}${after}`
			// move cursor:
			setTimeout(() => {
				cursorPos += emoji.native.length
				textarea.selectionStart = textarea.selectionEnd = cursorPos
			}, 10);
		},
	}
};
</script>

<template>
	<div class="p-3 chat-input-section">
		<div>

		</div>
		<form @submit.prevent="formSubmit" class="row">
			<div class="col">
				<div>
					<div class="input-group">
						<b-form-textarea
							ref="textarea"
							v-model="message"
							placeholder="Enter Message..."
							class="reply-textarea"
							rows="3"
						></b-form-textarea>
						<div class=" chat-input-links">
							<ul class="list-inline mb-0">
								<li class="list-inline-item emoji-item" v-click-outside="closeEmoji">
									<Picker v-show="showModal" :data="emojiData" set="facebook"
										@select="append" emoji="point_up"
										title="Pick emoji..." />
									<a
										href="javascript: void(0);"
										v-b-tooltip.hover
										@click="showModal=!showModal"
										placement="top"
										title="Emoji"
									>
										<i class="mdi mdi-emoticon-happy-outline"></i>
									</a>
								</li>
								<li class="list-inline-item" >
									<a
										href="javascript: void(0);"
										v-b-tooltip.hover
										placement="top"
										title="Images"
									>
										<i class="mdi mdi-file-image-outline"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div
						v-if="submitted && $v.message.$error"
						class="invalid-feedback"
					>
						<span>This value is required.</span>
					</div>
				</div>
			</div>

			<div class="col-send-button">
				<button
					type="submit"
					class="btn btn-primary btn-rounded chat-send"
				>
					<i class="fas fa-paper-plane"></i>
				</button>
			</div>
		</form>
	</div>
</template>
<style lang="scss">

	.col-send-button {
		padding-top: 87px;
	}

	.input-group {
		border-radius: 6px;
		background-color: #eff2f7;
		padding: 15px 15px 10px;
	}

	.reply-textarea {
		resize: none;
		border: 0 !important;
		background-color: #eff2f7 !important;
		caret-color: red;
	}

	.emoji-item {
		position: relative;
	}

	.emoji-mart {
		position: absolute;
		text-align: left;
		z-index: 1000;
		transform: translate(80px, -5px) translate(-100%, -100%);
	}
</style>
