<script>
import PageHeader from '@/components/page-header'
import Channel from '@/components/channel'
import appConfig from '@/app.config'
import { http } from '@/helpers/http'
import Switches from 'vue-switches'
import { config } from '@/helpers/config'
import Swal from 'sweetalert2'
import { alert } from "@/helpers/alert";
import CsatModal from "@/router/views/customer-rating/csat-modal";

export default {
	name: 'CsatConf',
	page: {
		title: 'Customer rating | Configuration',
		meta: [{ name: 'description', content: appConfig.description }]
	},
	components: {CsatModal, PageHeader, Switches, Channel },
	data() {
		return {
			csatList: [],
			title: 'Configuration',
			modalName: 'csatModal',
			modalData: {},
			enabled: false,
			items: [
				{
					text: 'Customer rating',
					href: '/'
				},
				{
					text: 'Configuration',
					active: true
				}
			]
		}
	},
	created() {
		this.getStatus()
		this.getList()
	},
	watch: {
		'$route.params'(to) {
			if (to.type) {
				this.type = to.type
			}
		}
	},
	methods: {
		async changeStatus(enabled) {
			try {
				let response = await http.post('csat/save', {
						json: {
							id: null,
							enabled: enabled
						}
					}).json()
				this.enabled = response
			} catch (e) {}
		},
		async changeStatusForId(id, enabled) {
			try {
				await http.post('csat/save', {
					json: {
						id: id,
						enabled: enabled
					}
				})
			} catch (e) {}
		},
		async getStatus() {
			let response
			try {
				response = await http.get('csat/enabled').json()
			} catch (e) {
				// Show error
				return
			}
			this.enabled = response
		},
		async getList() {
			try {
				await http.get('csat/query/list')
					.then((response) => response.json())
					.then((response) => {
						this.csatList = response
					})
			} catch (e) {}
		},

		async delete(id) {
			try {
				await http.delete(`csat/query/delete/${id}`).then(() => {
					Swal.fire('Deleted!', 'Your Csat has been deleted.', 'success')
					this.getList()
				})
			} catch (e) {}
		},

		showModal(data) {
			this.modalData = data
			this.$bvModal.show(this.modalName)
		},
		updateData(data) {
			if (data?.id) {
				this.update(data)
			} else {
				this.add(data)
			}
		},
		async update(data) {
			try {
				await http.post('csat/query/update', { json: data })
				this.$bvModal.hide(this.modalName)
				await this.getList()
			} catch (e) {
				alert.error(e.message)
				return;
			}
		},
		async add(data) {
			try {
				await http.post('csat/query/add', { json: data })
				this.$bvModal.hide(this.modalName)
				await this.getList()
			} catch (e) {
				alert.error(e.message)
				return;
			}
		},
		confirmDelete(id) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#34c38f',
				cancelButtonColor: '#f46a6a',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.delete(id)
				}
			})
		}
	}
}
</script>

<template>
	<div>
		<PageHeader :title="title" :items="items" />
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">Enable / disable CSAT</h4>
						<p class="card-title-desc">A mobile and touch friendly input spinner component for Bootstrap</p>
						<switches
							v-model="enabled"
							type-bold="true"
							:color="enabled ? 'success': 'default'"
							:emit-on-mount="false"
							class="mb-0"
							@input="changeStatus">
						</switches>
						<b-button class="btn-label float-right" variant="success" @click="showModal">
							<i class="mdi mdi-plus label-icon"></i> Add New
						</b-button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class>
					<div class="table-responsive">
						<table class="table project-list-table table-nowrap table-centered table-borderless">
							<thead>
								<tr>
									<th scope="col" style="width: 100px">#</th>
									<th scope="col">Message</th>
									<th scope="col">Channel</th>
									<th scope="col">Brand</th>
									<th scope="col">Enabled</th>
									<th scope="col">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="csat in csatList" :key="csat.id">
									<td>
										#{{ csat.id }}
									</td>
									<td>
										<h5 class="text-truncate font-size-14">
											<a href="javascript: void(0);" class="text-dark">{{ csat.title }}</a>
										</h5>
										<p class="text-muted mb-0">
											{{ csat.message }}
										</p>
									</td>
									<td>
										<div class="buttons">
											<Channel :channelId="csat.channel.id" />
										</div>
									</td>
									<td>
										<div class="buttons">
											{{ csat.brand.name }}
										</div>
									</td>
									<td>
										<switches
											v-model="csat.enabled"
											type-bold="true"
											:color="csat.enabled ? 'success' : 'default'"
											:emit-on-mount="false"
											class="pt-4"
											@input="changeStatusForId(csat.id, csat.enabled)"
										>
										</switches>
									</td>
									<td>
										<div class="button-items">
											<b-button variant="success" class="btn-label" @click="showModal(csat)"> <i class="mdi mdi-pencil label-icon"></i> Edit ({{ csat.csatQueryItemDtos.length }} items) </b-button>
											<b-button variant="danger" class="btn-label" @click="confirmDelete(csat.id)">
												<i class="bx bx-trash label-icon"></i> Delete
											</b-button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<CsatModal :data="modalData" :updateData="updateData" />
	</div>
</template>
