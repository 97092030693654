<script>
import PageHeader from '@/components/page-header'
import Channel from '@/components/channel'
import appConfig from '@/app.config'
import { http } from '@/helpers/http'
import AutomationModal from './automation-modal'
import JsonViewer from 'vue-json-viewer'
import Switches from 'vue-switches'
import { config } from '@/helpers/config'
import Swal from 'sweetalert2'
import { alert } from "@/helpers/alert";

export default {
	name: 'Automation',
	page: {
		title: 'Automation',
		meta: [{ name: 'description', content: appConfig.description }]
	},
	components: { PageHeader, Switches, AutomationModal, JsonViewer, Channel },
	data() {
		return {
			automations: [],
			title: 'Automation',
			automationModalName: 'automationModal',
			automationModalData: {},
			enabled: false
		}
	},
	created() {
		this.getAutomationStatus()
		this.getAutomationList()
	},
	watch: {
		'$route.params'(to) {
			if (to.type) {
				this.type = to.type
			}
		}
	},
	methods: {
		async changeAutomationStatus(enabled) {
			try {
				let response = await http.post('automation/save', {
						json: {
							id: null,
							enabled: enabled
						}
					}).json()
				this.enabled = response
			} catch (e) {}
		},
		async changeAutomationStatusForId(id, enabled) {
			try {
				await http.post('automation/save', {
					json: {
						id: id,
						enabled: enabled
					}
				})
			} catch (e) {}
		},
		async getAutomationStatus() {
			let response
			try {
				response = await http.get('automation/enabled').json()
			} catch (e) {
				// Show error
				return
			}
			this.enabled = response
		},
		async getAutomationList() {
			try {
				await http.get('automation/list')
					.then((response) => response.json())
					.then((response) => {
						this.automations = response
					})
			} catch (e) {}
		},

		async deleteAutomation(id) {
			try {
				await http.delete(`automation/delete/${id}`).then(() => {
					Swal.fire('Deleted!', 'Your automation has been deleted.', 'success')
					this.getAutomationList()
				})
			} catch (e) {}
		},

		showModal(data) {
			this.automationModalData = data
			this.$bvModal.show(this.automationModalName)
		},
		updateData(data) {
			if (data?.id) {
				this.update(data)
			} else {
				this.add(data)
			}
		},
		async update(data) {
			try {
				await http.post('automation/update', { json: data })
				this.$bvModal.hide(this.automationModalName)
				await this.getAutomationList()
			} catch (e) {
				alert.error(e.message)
				return;
			}
		},
		async add(data) {
			try {
				await http.post('automation/add', { json: data })
				this.$bvModal.hide(this.automationModalName)
				await this.getAutomationList()
			} catch (e) {
				alert.error(e.message)
				return;
			}
		},
		confirmDelete(automationId) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#34c38f',
				cancelButtonColor: '#f46a6a',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.deleteAutomation(automationId)
				}
			})
		}
	}
}
</script>

<template>
	<div>
		<div class="row">
			<div class="col-sm-6">
				<PageHeader :title="title" />
			</div>
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title">Enable / disable automation</h4>
						<p class="card-title-desc">A mobile and touch friendly input spinner component for Bootstrap</p>
						<switches
							v-model="enabled"
							type-bold="true"
							:color="enabled ? 'success': 'default'"
							:emit-on-mount="false"
							class="mb-0"
							@input="changeAutomationStatus">
						</switches>
						<b-button class="btn-label float-right" variant="success" @click="showModal">
							<i class="mdi mdi-plus label-icon"></i> Add New
						</b-button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class>
					<div class="table-responsive">
						<table class="table project-list-table table-nowrap table-centered table-borderless">
							<thead>
								<tr>
									<th scope="col" style="width: 100px">#</th>
									<th scope="col">Message</th>
									<th scope="col">Channel</th>
									<th scope="col">Type</th>
									<th scope="col">Brand</th>
									<th scope="col">Enabled</th>
									<th scope="col">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="automation in automations" :key="automation.id">
									<td>
										#{{ automation.id }}
									</td>
									<td>
										<h5 class="text-truncate font-size-14">
											<a href="javascript: void(0);" class="text-dark">{{ automation.title }}</a>
										</h5>
										<p class="text-muted mb-0">
											{{ automation.message }}
										</p>
									</td>
									<td>
										<div class="buttons">
											<Channel :channelId="automation.channel.id" />
										</div>
									</td>
									<td>
										<div class="buttons">
											{{ automation.autoReplyType }}
										</div>
									</td>
									<td>
										<div class="buttons">
											{{ automation.brand.name }}
										</div>
									</td>
									<td>
										<switches
											v-model="automation.enabled"
											type-bold="true"
											:color="automation.enabled ? 'success' : 'default'"
											:emit-on-mount="false"
											class="pt-4"
											@input="changeAutomationStatusForId(automation.id, automation.enabled)"
										>
										</switches>
									</td>
									<td>
										<div class="button-items">
											<b-button variant="success" class="btn-label" @click="showModal(automation)"> <i class="mdi mdi-pencil label-icon"></i> Edit </b-button>
											<b-button variant="danger" class="btn-label" @click="confirmDelete(automation.id)">
												<i class="bx bx-trash label-icon"></i> Delete
											</b-button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<AutomationModal :data="automationModalData" :updateAutomationData="updateData" />
	</div>
</template>
