<script>
import { required, email } from 'vuelidate/lib/validators';
import { config } from '@/helpers/config'
import { alert } from '@/helpers/alert'

export default {
	name: 'AgentModal',
	props: {
		data: { type: Object, required },
		updateAgentData: { type: Function },
	},
	components: { },
	data() {
		return {
			dirty: false,
			form: { ...this.data },
			config,
		};
	},
	validations: {
		form: {
			firstName: { required },
			email: { required, email },
		}
	},
	computed: {
		isNew() {
			return !this.form?.id
		}
	},
	watch: {
		data(data) {
			this.form = { ...data };
		}
	},
	methods: {
		async submit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				alert.error('Please fill all required fields')
			} else {
				this.updateAgentData(this.form)
			}
		},
		handleOk(bvModalEvt) {
			if (this.dirty || this.isNew) {
				bvModalEvt.preventDefault()
				this.submit()
			}
		},
		resetModal() {
			this.$v.$reset()
			this.dirty = false
			this.form = { ...this.data };

		},
		someThingChanged() {
			this.dirty = true
		}
	}
}
</script>

<template>
<b-modal id="agentModal" size="xl" title="" hide-header @ok="handleOk" @show="resetModal">
	<b-form @submit.prevent="submit" @change="someThingChanged()">
		<div class="row my-1">
			<div class="col-md-6">
				<b-form-group label="First Name*">
					<b-form-input
						v-model.trim="form.firstName"
						:class="{ 'is-invalid': $v.form.firstName.$error }"
						size="sm"
					></b-form-input>
					<div v-if="$v.form.firstName.$error" class="invalid-feedback">
						<span>First name is required</span>
					</div>
				</b-form-group>
			</div>
			<div class="col-md-6">
				<b-form-group label="Last Name">
					<b-form-input
						v-model.trim="form.lastName"
						size="sm"
					></b-form-input>
				</b-form-group>
			</div>
		</div>
		<div class="row my-1">
			<div class="col-md-6">
				<b-form-group label="Email*">
					<b-form-input
						v-model.trim="form.email"
						:class="{ 'is-invalid': $v.form.email.$error }"
						type="email"
						size="sm"
					></b-form-input>
					<div v-if="$v.form.email.$error" class="invalid-feedback">
						<span>Valid email is required.</span>
					</div>
				</b-form-group>
			</div>
			<div class="col-md-6">
				<b-form-group label="Active">
					<b-form-checkbox
						v-model="form.active"
						name="checkbox-1">
					</b-form-checkbox>
				</b-form-group>
			</div>
		</div>
		<div class="row my-1">
			<div class="col-md-6">
				<b-form-group label="Picture url">
					<b-form-input
						v-model.trim="form.pictureUrl"
						size="sm"
					></b-form-input>
				</b-form-group>
			</div>
			<div class="col-md-6">
				<b-form-group label="Role">
					<b-form-select
						id="input-object"
						v-model.trim="form.role"
						size="sm"
						placeholder="Select option"
						:options="config.roles"
					></b-form-select>
				</b-form-group>
			</div>
		</div>
	</b-form>
	</b-modal>
</template>
