<script>
import Multiselect from 'vue-multiselect'

export default {
	name: 'AddAgents',
	components: {
		Multiselect
	},
	data() {
		return {
			value: [],
			options: []
		}
	},
	methods: {
		getSearchClass() {
			return this.loading ? 'bx-loader bx-spin' : 'bxs-user-plus'
		},
		addTag(newTag) {
			const tag = {
				name: newTag,
				code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
			}
			this.options.push(tag)
			this.value.push(tag)
		},
		inviteAgents() {}
	}
}
</script>

<template>
	<div class="row">
		<div class="col-lg-12">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title">Add new agents</h4>
					<p class="card-title-desc">A mobile and touch friendly input spinner component for Bootstrap</p>
					<multiselect
						v-model="value"
						tag-placeholder="Add new email"
						placeholder="Add email addresses here"
						label="name"
						track-by="code"
						:options="options"
						:multiple="true"
						:taggable="true"
						@tag="addTag"
					>
					</multiselect>
					<b-button class="m-2 float-right" variant="success" @click="inviteAgents">
						<i :class="`bx ${this.getSearchClass()} font-size-16 align-middle mr-2`"></i> Invite agents
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>
