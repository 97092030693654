<script>
import { required, email } from 'vuelidate/lib/validators';
import { config } from '@/helpers/config'
import { alert } from '@/helpers/alert'
import Multiselect from 'vue-multiselect'
import Channel from '@/components/channel'

export default {
	name: 'CsatModal',
	props: {
		data: { type: Object, required },
		updateData: { type: Function },
	},
	components: { Multiselect, Channel },
	data() {
		return {
			brands: JSON.parse(localStorage.getItem(config.storage.BRANDS)),
			channels: JSON.parse(localStorage.getItem(config.storage.CHANNELS)),
			dirty: false,
			form: { ...this.data },
			config,
		};
	},
	validations: {
		form: {
			firstName: { required },
			email: { required, email },
		}
	},
	computed: {
		isNew() {
			return !this.form?.id
		}
	},
	watch: {
		data(data) {
			this.form = { ...data };
		}
	},
	methods: {
		async submit() {
			this.$v.$touch();
			this.updateData(this.form)
		},
		handleOk(bvModalEvt) {
			this.submit()
		},
		resetModal() {
			this.$v.$reset()
			this.dirty = false
			this.form = { ...this.data };

		},
		someThingChanged() {
			this.dirty = true
		}
	}
}
</script>

<template>
<b-modal id="csatModal" size="xl" title="" hide-header @ok="handleOk" @show="resetModal">
	<b-form @submit.prevent="submit" @change="someThingChanged()">
		<div class="row my-1">
			<div class="col-md-12">
				<b-form-group label="Message">
					<b-form-textarea
						id="textarea"
						placeholder="Enter at least 10 characters"
						v-model.trim="form.message"
						rows="2"
						no-resize
						max-rows="6"
					></b-form-textarea>
				</b-form-group>
			</div>
		</div>
		<div class="row my-1">
			<div class="col-md-6">
				<b-form-group label="Brand*">
					<multiselect
						v-model="form.brand"
						:options="brands"
						:searchable="true"
						:allow-empty="false"
						:close-on-select="true"
						name="ticketCategory"
						placeholder="Select brand"
						label="name"
						track-by="id"
					/>
				</b-form-group>
			</div>
<!--			<div class="col-md-6">-->
<!--				<b-form-group label="Type">-->
<!--					<multiselect-->
<!--						v-model="form.autoReplyType"-->
<!--						:options="config.autoReplyTypes"-->
<!--						:searchable="true"-->
<!--						:allow-empty="false"-->
<!--						:close-on-select="true"-->
<!--						placeholder="Select type"-->
<!--					/>-->
<!--				</b-form-group>-->
<!--			</div>-->
		</div>
		<div class="row my-1">
			<div class="col-md-6">
				<b-form-group label="Channel">
					<multiselect
						v-model="form.channel"
						:options="channels"
						:searchable="true"
						:allow-empty="false"
						:close-on-select="true"
						placeholder="Select channel"
						label="name"
						track-by="id"
					>
					<template slot="option" slot-scope="props">
						<div class="media">
							<div class="mr-2">
								<Channel :channelId="props.option.id" :hide-text="true" />
							</div>
							<div class="media-body">
								<span>{{ props.option.name }}</span>
							</div>
						</div>
					</template>
					</multiselect>
				</b-form-group>
			</div>
			<div class="col-md-6">
				<b-form-group label="Active">
					<b-form-checkbox
						v-model="form.enabled"
						name="checkbox-1">
					</b-form-checkbox>
				</b-form-group>
			</div>
		</div>
	</b-form>
	</b-modal>
</template>
