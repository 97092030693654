import moment from 'moment'

function getLocalTimeForGrid(row) {
	return config.getLocalTime(row.created, row.brandId)
}

function getLocalTime(utcTime, brandId) {
	let brand = config.ALL_BRANDS.find(element => element.id = brandId);
	let timeFormatted = moment(utcTime)
		.utcOffset(brand.timeOffset)
		.format(config.DATE_FORMAT_FE)
	console.log("IN: ", utcTime, " OUT: ", timeFormatted)
	return timeFormatted
}

export const config = {
	DATE_FORMAT_FE: 'DD/MM/yyyy HH:mm',
	gridItemsPerPage: [25, 50, 100, 200],
	storage: {
		AGENTS: 'all-agents',
		BRANDS: 'all-brands',
		CHANNELS: 'all-channels',
		CATEGORIES: 'all-categories',
		CATEGORIES_ENABLED: 'categories-enabled',
		DEFAULT_BRAND: 'default-brand',
		GENERAL: 'general-settings',
		NEW_TICKET_COUNT: 'new-ticket-count',
	},
	channels: {
		1: 'Email message',
		2: 'Facebook chat',
		3: 'Facebook feed',
		4: 'Live chat',
	},
	isSecure: window.location.protocol === 'https:',
	roles: [
		'ADMIN',
		'AGENT'
	],
	autoReplyTypes: [
		'GREETING',
		'NOTIFY'
	],
	maxToasts: 5,
	ALL_BRANDS: JSON.parse(localStorage.getItem('all-brands')),
	getLocalTime,
	getLocalTimeForGrid
}
