import ky from 'ky'
import router from '../router'

const prefixHttp = process.env.NODE_ENV === 'production' ? '/api' : '/api'
const http = ky.create({
	prefixUrl: prefixHttp,
	timeout: 10000, // 10 second
	hooks: {
		beforeRequest: [
			(request) => {
				request.headers.set('Content-Type', 'application/json')
			},
		],
		afterResponse: [
			async (request, options, response) => {
				if (response.status === 401 || response.status === 403) {
					console.error({response})
					await router.push({name: 'login'})
				} else {
					return response
				}
			},
		],
	},
})


export { http }
