<script>
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { http } from '@/helpers/http'
import { alert } from '@/helpers/alert'

import AgentList from './components/agent-list'
import AgentGrid from './components/agent-grid'
import AgentModal from '@/router/views/settings/components/agent-modal'
import AddAgents from '@/router/views/settings/components/add-agents'

/**
 * Shops component
 */
export default {
	name: 'Agents',
	page: {
		title: 'Agents',
		meta: [{ name: 'description', content: appConfig.description }]
	},
	components: { PageHeader, AgentList, AgentGrid, AgentModal, AddAgents },
	data() {
		return {
			type: 'grid',
			agents: [],
			title: 'Agents',
			agentModalData: {}
		}
	},
	created() {
		this.getAgentList()
		const type = this.$route.params.type
		if (type) {
			this.type = type
		}
	},
	watch: {
		'$route.params'(to) {
			if (to.type) {
				this.type = to.type
			}
		}
	},
	methods: {
		async getAgentList() {
			let response
			try {
				response = await http.get('settings/agents/list').json()
			} catch (e) {
				// Show error
				return
			}
			this.agents = response
		},
		async activeClicked(agent) {
			try {
				await http.post(`settings/agents/${agent.id}/${agent.active}`)
			} catch (e) {
				// Show error
				return
			}
		},
		updateData(data) {
			if (data?.id) {
				this.updateAgent(data)
			} else {
				this.addAgent(data)
			}
		},
		async addAgent(data) {
			try {
				await http.post('settings/agents/add', { json: data })
				this.getAgentList()
				this.$bvModal.hide('agentModal')
			} catch (e) {
				alert.error(e.message)
				return;
			}
		},
		async updateAgent(data) {
			try {
				await http.put('settings/agents/update', { json: data })
				this.getAgentList()
				this.$bvModal.hide('agentModal')
			} catch (e) {
				alert.error(e.message)
				return;
			}
		},
		showModal(data) {
			this.agentModalData = data
			this.$bvModal.show('agentModal')
		},
	}
}
</script>

<template>
	<div>
		<div class="row">
			<div class="col-sm-6">
				<PageHeader :title="title" />
			</div>
			<div class="col-sm-6">
				<div class="float-right">
					<b-nav pills>
						<b-nav-item disabled>View : </b-nav-item>
						<b-nav-item :active="type === 'list'" v-b-tooltip.hover title="List" to="/settings/agents/list"><i class="mdi mdi-format-list-bulleted"></i></b-nav-item>
						<b-nav-item :active="type === 'grid'" v-b-tooltip.hover title="Grid" to="/settings/agents/grid"><i class="mdi mdi-view-grid-outline"></i></b-nav-item>
					</b-nav>
				</div>
			</div>
		</div>
		<AddAgents />
		<AgentGrid v-if="type === 'grid'" :agents="agents" :activeClick="activeClicked" :openModal="showModal" />
		<AgentList v-else :agents="agents" :openModal="showModal" />
		<AgentModal :data="agentModalData" :updateAgentData="updateData" />
	</div>
</template>
