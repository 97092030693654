<script>
import Multiselect from 'vue-multiselect'
import CKEditor from 'ckeditor4-vue';

export default {
	name: 'EmailEditor',
	components: { ckeditor: CKEditor.component, Multiselect },
	props: {
		email: { type: Object, required: false, default: () => ({}) }
	},
	data() {
		return {
			editorData:
				'<h3>Hi there,</h3><p><br>I\'ll take my boat.</p><blockquote><p>Best regards 👏&nbsp;<br>– Tom Cruise</p></blockquote>',
			editorConfig: {
				allowedContent: true,
				startupFocus: true,
				disableAutoInline: true,
				extraPlugins: 'sharedspace,sourcedialog',
      			removePlugins: 'floatingspace,maximize,resize,about',
				sharedSpaces: {
					top: 'top2',
				}

			},
			value: null,
			showCc: false,
			showBcc: false,
			options: [
				{
					id: 1,
					name: 'Julia Smith',
					email: 'Julia@Smith.com',
					img: require('@/assets/images/users/avatar-1.jpg')
				},
				{
					id: 2,
					name: 'Steven Franklin',
					email: 'Steven@Franklin.com',
					img: require('@/assets/images/users/avatar-2.jpg')
				},
				{
					id: 3,
					name: 'Adam Miller',
					email: 'adam@miller.com',
					img: require('@/assets/images/users/avatar-3.jpg')
				},
				{
					id: 4,
					name: 'Stephen Hadley',
					email: 'Stephen@Hadley.com',
					img: require('@/assets/images/users/avatar-4.jpg')
				},
				{
					id: 5,
					name: 'Humberto D. Champion',
					email: 'Humberto@Champion.com',
					img: require('@/assets/images/users/avatar-6.jpg')
				},
				{ id: 6, name: 'John Smith', email: 'John@Smith.com' }
			]
		}
	},
	computed: {},
	methods: {
		sendEmail() {}
	}
}
</script>
<template>
	<div>
		<b-card footer-tag="footer">
			<b-card-text>
				<form>
					<b-input-group class="border-bottom my-1">
						<multiselect
							class="no-border input-group-element"
							placeholder="To"
							track-by="id"
							label="name"
							v-model="value"
							:options="options"
							:multiple="true"
						>
							<template slot="option" slot-scope="props">
								<div class="media">
									<div class="image mr-3">
										<template v-if="props.option.img">
											<img
												class="rounded-circle avatar-xs"
												:src="props.option.img"
											/>
										</template>
										<template v-else>
											<div
												class="rounded-circle avatar-xs bg-light text-center"
											>
												{{ props.option.name.charAt(0) }}
											</div>
										</template>
									</div>
									<div class="media-body">
										<h5 class="">{{ props.option.name }}</h5>
										<small class="text-muted">{{ props.option.email }}</small>
									</div>
								</div>
							</template>
						</multiselect>
						<b-input-group-append>
							<b-button
								class="btn-outline-transparent"
								variant="light"
								@click="showCc = !showCc"
								>CC</b-button
							>
							<b-button
								class="btn-outline-transparent"
								variant="light"
								@click="showBcc = !showBcc"
								>BCC</b-button
							>
						</b-input-group-append>
					</b-input-group>

					<b-input-group class="border-bottom my-1" v-if="showCc">
						<input type="email" class="no-border form-control" placeholder="CC" />
						<b-input-group-append>
							<b-button
								variant="light"
								class="btn-outline-transparent"
								@click="showCc = false"
							>
								<i class="bx bx-x font-size-20"></i>
							</b-button>
						</b-input-group-append>
					</b-input-group>
					<b-input-group class="border-bottom my-1" v-if="showBcc">
						<input type="email" class="no-border form-control" placeholder="BCC" />
						<b-input-group-append>
							<b-button
								variant="light"
								class="btn-outline-transparent"
								@click="showBcc = false"
							>
								<i class="bx bx-x font-size-20"></i>
							</b-button>
						</b-input-group-append>
					</b-input-group>
					<input
						type="text"
						class="border-bottom form-control my-1"
						placeholder="Subject"
						v-model="email.subject"
					/>
					<div class="">
						<div id="top2" class="sticky">
							<!-- This div will handle all toolbars. -->
						</div>
						<div class="ckeditor">
							<ckeditor type="inline" v-model="email.message" :config="editorConfig"></ckeditor>
						</div>
					</div>
				</form>
			</b-card-text>
			<template #footer>
				<b-button variant="primary" @click="sendEmail">
					Send
					<i class="fab fa-telegram-plane ml-1"></i>
				</b-button>
				<b-button variant="light" class="btn-outline-transparent">
					<i class="bx bx-paperclip font-size-20"></i>
				</b-button>
			</template>
		</b-card>
	</div>
</template>

<style lang="scss">

.sticky {
	position: sticky;
	top: 70px;
	z-index: 10;
}

.ckeditor {
	overflow-x: auto;
}

// Hack
a.cke_button_expandable, a.cke_button_on, a.cke_button_off:hover, a.cke_button_off:focus, a.cke_button_off:active {
	padding: 4px 6px !important;
	border: none !important;
}

a.cke_button_on {
	box-shadow: 1px 1px #bcbcbc;
}

a.cke_combo_button {
	padding: 1px !important;
	border: none !important;
	margin: 0 !important;

	&:active {
		box-shadow: 1px 1px #bcbcbc;
	}
}

</style>