<script>
import PageHeader from "@/components/page-header";
import DateRange from '@/components/date-range'
import Multiselect from "vue-multiselect";
import moment from 'moment'
import { config } from "@/helpers/config";
import { http } from '@/helpers/http';
import Channel from '@/components/channel'
import {
	DxDataGrid,
	DxColumn,
	DxGrouping,
	DxGroupPanel,
	DxPager,
	DxPaging,
	DxSearchPanel,
	DxExport
} from 'devextreme-vue/data-grid'

import 'devextreme/data/odata/store'

export default {
    components: {
        PageHeader,
		DateRange,
		Multiselect,
		Channel,
		DxDataGrid,
		DxColumn,
		DxGrouping,
		DxGroupPanel,
		DxPager,
		DxPaging,
		DxSearchPanel,
		DxExport
    },
    data() {
        return {
            title: "Search",
            items: [{
                    text: "Tickets",
                    href: "/"
                },
                {
                    text: "Search",
                    active: true
                }
            ],
			creationTime: {
				startDate: moment().subtract(7, 'days').startOf('day'),
				endDate: moment(),
			},
			tickets: [],
			pageSizes: [10, 25, 50, 100],
			dataSource: null,
			loading: false,

			allBrands: null,
			allChannels: null,
			allAgents: null,

			ticketId: null,
			selectedBrands: null,
			selectedChannels: null,
			selectedAgents: null,

        };
    },
	created() {
		this.allBrands = JSON.parse(localStorage.getItem(config.storage.BRANDS))
		this.selectedBrands = this.allBrands

		this.allChannels = JSON.parse(localStorage.getItem(config.storage.CHANNELS))
		this.allAgents = JSON.parse(localStorage.getItem(config.storage.AGENTS))
	},
	destroyed() {
		if (this.dataSource) {
			this.dataSource.dispose()
		}
		this.dataSource = null;
	},
	computed: {
		currentDate() {
			return new Date().toISOString().split('T')[0]
		},
	},
	methods: {
		async searchTickets() {
			this.loading = true
			try {
				this.dataSource = await http
					.post('ticket/search/all', {
						json: {
							id: this.ticketId,
							from: this.creationTime?.startDate,
							to: this.creationTime?.endDate,
							brands: this.selectedBrands?.map((brand) => brand.id),
							agents:  this.selectedAgents?.map((agent) => agent.id),
							channels: this.selectedChannels?.map((channel) => channel.id),
						},
					})
					.json()

			} catch (e) {
				//return await showHttpError(e)
			} finally {
				this.loading = false
			}

		},
		async resetTicketSearch() {
			this.allBrands = JSON.parse(localStorage.getItem(config.storage.BRANDS))
			this.selectedBrands = this.allBrands

			this.allChannels = JSON.parse(localStorage.getItem(config.storage.CHANNELS))
			this.allAgents = JSON.parse(localStorage.getItem(config.storage.AGENTS))

			this.ticketId = null
			this.selectedChannels = null
			this.selectedAgents = null
			this.dataSource = null
		},
		openTicketInNewTab(ticketId) {
			let routeData = this.$router.resolve({
				name: 'ticket',
				params: { id: ticketId },
			})
			window.open(routeData.href, '_blank')
		},
		getSearchClass() {
			return this.loading ? "bx-loader bx-spin" : "bx-search"
		},
		creationDateUpdate(creationTime) {
			this.creationTime = Object.assign(
				{},
				this.creationTime,
				creationTime,
			)
		},
	}
};
</script>

<template>
	<div>
    <PageHeader :title="title" :items="items" />
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title mb-4">Search historical tickets from the system</h4>

						<b-form>
							<div class="row">
								<div class="col-lg-4">
									<b-form-group label="Ticket ID">
										<b-form-input type="number" v-model="ticketId"></b-form-input>
									</b-form-group>
								</div>
								<div class="col-lg-4">
									<date-range
										label="Ticket created"
										:date-range="creationTime"
										@dateUpdate="creationDateUpdate"
									/>
								</div>

								<div class="col-lg-4">
									<b-form-group
										label="Brands"
									>
										<multiselect
											v-model="selectedBrands"
											:options="allBrands"
											:allow-empty="true"
											:searchable="true"
											:multiple="true"
											label="name"
											track-by="id"
										/>
									</b-form-group>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-4">
									<b-form-group
										label="Channels"
									>
										<multiselect
											v-model="selectedChannels"
											:options="allChannels"
											:allow-empty="true"
											:searchable="true"
											:multiple="true"
											label="name"
											track-by="id"
											>
											<template slot="option" slot-scope="props">
												<div class="media">
													<div class="mr-2">
														<Channel :channelId="props.option.id" :hide-text="true" />
													</div>
													<div class="media-body">
														<span class="">{{ props.option.name }}</span>
													</div>
												</div>
											</template>
										</multiselect>
									</b-form-group>
								</div>
								<div class="col-lg-4">
									<b-form-group
										label="Agents"
									>
										<multiselect
											v-model="selectedAgents"
											:options="allAgents"
											:allow-empty="true"
											:searchable="true"
											:multiple="true"
											label="fullName"
											track-by="id"
										/>
									</b-form-group>
								</div>
							</div>

							<div class="float-right m-2">
								<b-button variant="primary" @click="resetTicketSearch">Empty</b-button>
								<b-button class="m-2" variant="primary" @click="searchTickets">
									<i :class="`bx ${ this.getSearchClass() } font-size-16 align-middle mr-2`"></i> Search tickets
								</b-button>
							</div>
						</b-form>
					</div>
				</div>
			</div>
		</div>
		<DxDataGrid
			:data-source="dataSource"
			:remote-operations="false"
			:allow-column-reordering="true"
			:row-alternation-enabled="true"
			selection-mode="single"
			:hover-state-enabled="false"
			:show-borders="false"
			:column-auto-width="true"
		>
			<DxColumn
				data-field="id"
				caption="Ticket ID"
				alignment="left"
				width="90"
				cell-template="html-bold-id"
			/>
			<dx-column
				:allow-exporting="false"
				:allow-sorting="false"
				:allow-filtering="false"
				caption="Channel"
				cell-template="channelTemplate"
			/>
			<template #html-bold-id="{ data: row }">
				<div class="text-body font-weight-bold" v-html="'#' + row.value"></div>
			</template>
			<DxColumn data-field="client" data-type="string" />
			<DxColumn
				:allow-grouping="false"
				data-field="created"
				data-type="datetime"
			/>
			<DxColumn data-field="category" data-type="string" />
			<DxColumn data-field="brand" data-type="string" />
			<DxColumn
				data-field="status"
				cell-template="statusTemplate"
				alignment="left"
			/>
			<DxColumn data-field="agent" caption="Assigned" data-type="string" />
			<DxColumn
				:allow-exporting="false"
				:allow-sorting="false"
				:allow-filtering="false"
				caption="View details"
				cell-template="actionsTemplate"
			/>
			<DxExport
				:enabled="true"
				:file-name="`${currentDate}-${this.$route.name}`"
			/>
			<DxGroupPanel :visible="false" />
			<DxSearchPanel
				:width="240"
				:visible="true"
				:highlight-case-sensitive="true"
			/>
			<DxGrouping :auto-expand-all="false" />
			<DxPager
				:visible="true"
				:allowed-page-sizes="pageSizes"
				:show-page-size-selector="true"
				:show-navigation-buttons="true"
				:show-info="true"
			/>
			<DxPaging :page-size="10" />
			<template #statusTemplate="{ data }">
				<div>
					<big class="mr-1">
						<b-badge pill variant="primary">{{ data.data.ticketStatus }}</b-badge>
					</big>
					<b-badge v-if="data.data.unreadMessages > 0" variant="danger">{{ data.data.unreadMessages }}</b-badge>
				</div>
			</template>
			<div slot="actionsTemplate" slot-scope="{ data }" class="buttons">
				<button type="button" @click="openTicketInNewTab(data.data.id)" class="btn btn-primary btn-sm btn-rounded">
					View Details
				</button>
			</div>
			<div slot="channelTemplate" slot-scope="{ data }" class="buttons">
				<Channel :channelId="data.data.channelId" :attachmentCount="data.data.attachmentCount" />
			</div>
		</DxDataGrid>
	</div>
</template>
