import store from '@/state/store';
import ticketList from './views/ticket/ticket-list';
import ticketSearch from './views/ticket/ticket-search';
import automation from './views/automatic-reply/automation';
import csat from './views/customer-rating/ratings';
import csatConf from './views/customer-rating/csat-conf';
import agents from './views/settings/agents';

export default [
	{
		path: '/login',
		name: 'login',
		component: () => import('./views/account/login'),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters['auth/loggedIn']) {
					// Redirect to the home page instead
					next({ name: 'default' });
				} else {
					// Continue to the login page
					next();
				}
			}
		}
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('./views/account/register'),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters['auth/loggedIn']) {
					// Redirect to the home page instead
					next({ name: 'default' });
				} else {
					// Continue to the login page
					next();
				}
			}
		}
	},
	{
		path: '/forgot-password',
		name: 'Forgot password',
		component: () => import('./views/account/forgot-password'),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters['auth/loggedIn']) {
					// Redirect to the home page instead
					next({ name: 'default' });
				} else {
					// Continue to the login page
					next();
				}
			}
		}
	},
	{
		path: '/404',
		name: '404',
		component: require('./views/utility/404').default
	},
	// Redirect any unmatched routes to the 404 page. This may
	// require some server configuration to work in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	{
		path: '*',
		redirect: '404'
	},
	{
		path: '/charts/apex',
		name: 'Apex chart',
		meta: { authRequired: true },
		component: () => import('./views/charts/apex')
	},
	{
		path: '/charts/chartjs',
		name: 'Chartjs chart',
		meta: { authRequired: true },
		component: () => import('./views/charts/chartjs/index')
	},
	{
		path: '/charts/chartist',
		name: 'Chartist chart',
		meta: { authRequired: true },
		component: () => import('./views/charts/chartist')
	},
	{
		path: '/pages/404',
		name: 'Error-404',
		meta: { authRequired: true },
		component: () => import('./views/utility/404')
	},
	{
		path: '/pages/500',
		name: 'Error-500',
		meta: { authRequired: true },
		component: () => import('./views/utility/500')
	},
	{
		path: '',
		name: 'mainLayout',
		component: () => import('./layouts/main'),
		children: [
			{
				path: '/',
				name: 'default',
				meta: {
					authRequired: true
				},
				component: () => import('./views/home/default')
			},
			{
				path: '/settings/',
				name: 'settingsMain',
				component: { render: h => h('router-view') },
				children: [
					{
						path: 'agents',
						name: 'agentSettings',
						meta: {
							authRequired: true
						},
						component: agents,
						children: [
							{
								path: ':type',
								name: 'agentSettings-type',
								component: agents
							},
						]
					},
					{
						path: 'types',
						name: 'requestTypes',
						meta: {
							authRequired: true
						},
						component: () => import('./views/settings/request-types/request-types')
					},
					{
						path: 'general',
						name: 'generalSettings',
						meta: {
							authRequired: true
						},
						component: () => import('./views/settings/general-settings/general-settings')
					},
				]
			},
			{
				path: '/ticket/',
				name: 'ticketMain',
				meta: { requiresAuth: true },
				component: { render: h => h('router-view') },
				children: [
					{
						path: ':id(\\d+)',
						name: 'ticket',
						meta: { requiresAuth: true },
						component: ticketList
					},
					{
						path: 'list',
						name: 'ticket-list',
						component: ticketList
					},

					{
						path: 'search',
						name: 'ticket-search',
						component: ticketSearch
					},
				]
			},
			{
				path: '/automation',
				name: 'automation',
				component: automation
			},
			{
				path: '/csat/',
				name: 'csat',
				meta: { requiresAuth: true },
				component: { render: h => h('router-view') },
				children: [
					{
						path: 'configuration',
						name: 'csat-configuration',
						component: csatConf
					},
					{
						path: 'ratings',
						name: 'csat-ratings',
						component: csat
					},
				]
			},
		]
	}
];
