<script>
import {
	DxDataGrid,
	DxColumn,
	DxGrouping,
	DxGroupPanel,
	DxPager,
	DxPaging,
	DxSearchPanel,
} from 'devextreme-vue/data-grid'
import 'devextreme/data/odata/store'
import { config } from '@/helpers/config'
import Channel from '@/components/channel'
import Status from '@/components/status'

let collapsed = false

export default {
	name: 'TicketGrid',
	props: {
		tickets: {
			type: Array,
			default: function () {
				return []
			}
		},
		name: { type: String },
		method: { type: Function }
	},
	components: {
		DxDataGrid,
		DxColumn,
		DxGrouping,
		DxGroupPanel,
		DxPager,
		DxPaging,
		DxSearchPanel,
		Channel,
		Status
	},
	data() {
		return {
			config,
			pageSizes: [10, 25, 50, 100],
			onContentReady: function (e) {
				if (!collapsed) {
					e.component.expandRow(['EnviroCare'])
					collapsed = true
				}
			}
		}
	},
	computed: {
		currentDate() {
			return new Date().toISOString().slice(0, 10)
		}
	},
	methods: {
		clickRow(data) {
			this.method(data.data)
		},
		getChannelName(channelId) {
			return config.channels[channelId]
		},
	}
}
</script>

<template>
	<DxDataGrid
		:data-source="tickets"
		:remote-operations="false"
		:allow-column-reordering="true"
		:row-alternation-enabled="true"
		selection-mode="single"
		:hover-state-enabled="false"
		:show-borders="false"
		:column-auto-width="true"
		:on-row-click="clickRow"
		@content-ready="onContentReady"
	>
		<DxColumn
			data-field="id"
			caption="Ticket ID"
			alignment="left"
			width="90"
			cell-template="html-bold-id"
		/>
		<dx-column
			:allow-exporting="false"
			:allow-sorting="false"
			:allow-filtering="false"
			caption="Channel"
			cell-template="channelTemplate"
		/>
		<template #html-bold-id="{ data: row }">
			<div class="text-body font-weight-bold" v-html="'#' + row.value"></div>
		</template>
		<DxColumn data-field="client" data-type="string" />
		<DxColumn
			:allow-grouping="false"
			data-field="created"
			data-type="datetime"
			:calculate-cell-value="config.getLocalTimeForGrid"
		/>
		<DxColumn data-field="category" data-type="string" />
		<DxColumn data-field="brand" data-type="string" />
		<DxColumn
			data-field="status"
			cell-template="statusTemplate"
			alignment="left"
		/>
		<DxColumn data-field="agent" caption="Assigned" data-type="string" />
		<DxColumn
			:allow-exporting="false"
			:allow-sorting="false"
			:allow-filtering="false"
			caption="View details"
			cell-template="actionsTemplate"
		/>

		<DxPager
			:visible="true"
			:allowed-page-sizes="pageSizes"
			:show-page-size-selector="true"
			:show-navigation-buttons="true"
			:show-info="true"
		/>
		<DxPaging :page-size="10" />
		<template #statusTemplate="{ data }">
			<Status :status="data.data.ticketStatus" :unread-messages="data.data.unreadMessages" />
		</template>
		<template #actionsTemplate class="buttons">
			<button type="button" class="btn btn-primary btn-sm btn-rounded">
				View Details
			</button>
		</template>
		<template #channelTemplate="{ data }" class="buttons">
			<Channel :channelId="data.data.channelId" :attachmentCount="data.data.attachmentCount" />
		</template>
	</DxDataGrid>
</template>
<style lang="scss">
</style>
