<template>
	<b-form-group :label="label" label-for="date-range">
		<date-range-picker
			ref="picker"
			v-model="dateRangeCopy"
			:locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:MM:ss' }"
			:ranges="dateRanges"
			linked-calendars
			date-util="moment"
			class="picker"
			control-container-class="form-control"
			time-picker
			:opens="opens"
			@update="onDateRangeUpdate"
		>
			<div slot="input" slot-scope="picker">
				{{ picker.startDate | date }} - {{ picker.endDate | date }}
			</div>
		</date-range-picker>
	</b-form-group>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'

export default {
	name: 'DateRange',
	components: { DateRangePicker },
	props: {
		label: {
			type: String,
			required: false,
			default: 'Date range',
		},
		dateRange: {
			type: Object,
			required: true,
		},
		opens: {
			type: String,
			default: 'center',
		},
		dateRanges: {
			type: Object,
			default: () => ({
				None: [null, null],
				Today: [moment().startOf('day'), moment()],
				Yesterday: [
					moment().subtract(1, 'days').startOf('day'),
					moment().subtract(1, 'days').endOf('day'),
				],
				'Last 7 days': [
					moment().subtract(7, 'days').startOf('day'),
					moment(),
				],
				'Last 30 days': [
					moment().subtract(1, 'month').startOf('day'),
					moment(),
				],
				'This month': [
					moment().startOf('month'),
					moment().endOf('month'),
				],
				'Last month': [
					moment().subtract(1, 'month').startOf('month'),
					moment().subtract(1, 'month').endOf('month'),
				],
			}),
		},
	},
	data() {
		return {
			dateRangeCopy: {},
		}
	},
	watch: {
		dateRange: {
			immediate: true,
			handler(newDateRange) {
				this.dateRangeCopy = newDateRange
			},
		},
	},
	methods: {
		onDateRangeUpdate(dateRange) {
			let { startDate, endDate } = dateRange
			const payload = { startDate, endDate }
			startDate = moment(startDate)
			endDate = moment(endDate)

			// if (Math.abs(endDate.diff(startDate, 'days')) > config.limitDays) {
			// 	this.resetDateRange()
			// 	notify(
			// 		`The period selected is more than ${config.limitDays} days. Please select a shorter date range.`,
			// 		'error',
			// 		config.errorDisplayTime,
			// 	)
			// } else {
			//
			// }
			this.$emit('dateUpdate', payload)
		},
	},
}
</script>

<style lang="scss" scoped>
.picker {
	width: 100%;
}
</style>
<style lang="scss">
.daterangepicker.show-calendar .ranges {
	padding: 0;
}
</style>