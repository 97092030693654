<script>
import TicketGrid from './ticket-grid'
import TicketDetail from './ticket-detail/ticket-detail'
import Multiselect from 'vue-multiselect'
import PageHeader from '@/components/page-header'
import Channel from '@/components/channel'

import { http } from '@/helpers/http'
import { config } from '@/helpers/config'

export let ticketListTabs = ['ALL', 'MY', 'NEW', 'SOLVED']

export default {
	name: 'TicketList',
	components: { TicketGrid, TicketDetail, Multiselect, PageHeader, Channel },
	computed: {
		tabs() {
			return this.tabIds.map((tab, index) => {
				return {
					id: tab,
					title: index >= ticketListTabs.length ? 'TICKET-' + tab : tab.toUpperCase(),
					badge: this.ticketHeader[tab],
					isClosable: index >= ticketListTabs.length,
					group: index < ticketListTabs.length
				}
			})
		}
	},
	data() {
		return {
			title: 'Tickets',
			tabIds: [...ticketListTabs],
			tabIndex: 0,
			showTicketList: true,
			ticketId: null,
			tickets: [],
			ticketHeader: {},
			selectedTab: ticketListTabs[0],
			allBrands: JSON.parse(localStorage.getItem(config.storage.BRANDS)),
			selectedbrands: JSON.parse(localStorage.getItem(config.storage.BRANDS)),
			items: [
				{
					text: 'Dashboards',
					href: '/'
				},
				{
					text: 'Default',
					active: true
				}
			]
		}
	},
	async created() {
		const ticketId = parseInt(this.$route.params.id)
		if (ticketId) {
			this.addTab(ticketId)
		}

		// Get selected group
		this.updateLists()
	},
	watch: {
		'$route.params'(to) {
			if (to?.id) {
				const ticketId = parseInt(to.id)
				this.addTab(ticketId)
			}
		}
	},
	methods: {
		closeTab(tab) {
			const i = this.tabIds.indexOf(tab.id)
			this.tabIds.splice(i, 1)
			if (this.selectedTab !== tab.id) {
				return
			}
			let index = this.tabIndex
			if (index > this.tabs.length - 1) {
				index = this.tabs.length - 1
			}
			this.clickTab(this.tabs[index])
		},
		clickTab(tab) {
			if (!tab.group) {
				this.openTicket(tab.id)
			} else {
				this.$router.push({
					name: 'ticket-list'
				}).catch(() => {})
				this.changeTabInfo(tab)
			}
		},
		openTicket(ticketId) {
			this.$router.push({
				name: 'ticket',
				params: { id: ticketId }
			}).catch(() => {})
		},
		changeTabInfo(tab) {
			this.showTicketList = tab.group
			this.selectedTab = tab.id

			if (!tab.group) {
				this.ticketId = tab.id
				this.tickets = []
			} else {
				this.updateLists(tab.id)
			}
		},
		changeBootstrapTabIndex(index) {
			this.$nextTick(() => {
				requestAnimationFrame(() => {
					setTimeout(() => {
						this.tabIndex = index
					}, 10)
				})
			})
		},
		addPaddingClass() {
			return this.showTicketList ? "p-3" : "p-0"
		},
		addTab(id) {
			const i = this.tabIds.indexOf(id)
			if (i < 0) {
				this.tabIds.push(id)
			}
			this.openTab(id)
		},
		openTab(id) {
			const index = this.tabs.findIndex((x) => x.id === id)
			this.changeTabInfo(this.tabs[index])
			this.changeBootstrapTabIndex(index)
		},
		updateBrandSelection() {
			if (this.tabIndex < ticketListTabs.length) {
				this.updateLists()
			} else {
				this.getHeaders()
			}
		},
		updateLists(type = this.selectedTab) {
			this.getHeaders()
			this.getTicketList(type)
		},
		async getTicketList(source) {
			let brands = this.selectedbrands.map((brand) => brand.id)
			let response
			try {
				response = await http.post('ticket/list', {
						json: {
							agentId: 1,
							ticketSource: source,
							brands
						}
					}).json()
			} catch (e) {
				// Show error
			}
			this.tickets = response
		},
		async getHeaders() {
			let response
			let brands = this.selectedbrands.map((brand) => brand.id)
			try {
				response = await http.post('ticket/header', {
						json: {
							brands
						}
					}).json()

				localStorage.setItem(config.storage.NEW_TICKET_COUNT, response.NEW)
			} catch (e) {
				// Show error
			}
			this.ticketHeader = response
		},
		rowClick(data) {
			this.openTicket(data.id)
		},
		async endDemo(key) {
			http.post(`settings/endDemo/${key}`)
				.then((response) => response.json())
				.then((response) => {
				})
		}
	}
}
</script>

<template>
	<div>
		<PageHeader :title="title" :items="items" />
		<b-alert show variant="warning" >
			<i class="mdi mdi-alert-circle-outline mr-2"></i>
			<a class="alert-link" v-on:click="endDemo('DEMO_TICKET_LIST')"> Press here</a> to dismiss the demo mode.
		</b-alert>
		<multiselect
			hidden="hidden"
			class="no-border"
			v-model="selectedbrands"
			:options="allBrands"
			label="name"
			track-by="id"
			:multiple="true"
			@input="updateBrandSelection"
			style="width:calc(100% - 200px)">
		</multiselect>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<b-tabs nav-class="nav-tabs-custom"
								:content-class="`${addPaddingClass()} text-muted`" v-model="tabIndex">
							<b-tab
								v-for="(tab, index) in tabs"
								:key="index"
								@click="clickTab(tab)">
								<template #title>
									<Channel v-if="tab.isClosable" :channelId=1 :hide-text="true" />
									<span>
										{{ tab.title }}
										<b-badge
											pill v-if="tab.badge > 0"
											variant="info">
											{{ tab.badge }}
										</b-badge>
									</span>
									<button
										v-if="tab.isClosable"
										class="close ml-1"
										type="button"
										@click.stop="closeTab(tab)">
										×
									</button>
								</template>
							</b-tab>
						</b-tabs>
						<div class="row">
							<div class="col-sm-12">
								<TicketGrid v-if="showTicketList" :tickets="tickets" :method="rowClick" :name="selectedTab" />
								<TicketDetail v-else :ticketId="ticketId" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss"></style>
